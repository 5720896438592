import React, { useState, useMemo, useEffect, useCallback } from "react"
import { observer } from "mobx-react-lite"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjScreenType } from "./projects-table-props"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import { DLI18nProps } from "../../../common-models/types/common-props"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import ReactTableV8 from "../../../components/basic-elements/tables/DLReactTable"
import { Column, Table } from "@tanstack/react-table"
import DLComboBoxWithCheckbox from "../../basic-elements/autocomplete-fields/DLComboBoxWithCheckbox"
import DLDateRangepicker from "../../basic-elements/date-picker/DLDateRangePicker"
import DLYearpicker from "../../basic-elements/date-picker/DLYearpicker"
import { InputFieldForTableV8 } from "../../basic-elements/tables/InputFieldForTable"
import { DLButton } from "../../basic-elements/buttons"
import { Icon } from "@mdi/react"
import { mdiMenuDown, mdiPlus } from "@mdi/js"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import SharedProjectsPageHeader from "./SharedProjectsPageHeader"
import { addDays, format } from "date-fns"
import {
	CreateProjectMethod,
	initialCreateProjectInputs,
} from "../../../components/combined-elements/create-project/create-project.data-props"
import styled from "styled-components"
import {
	DLContextMenuWithButton,
	DLInputField,
	DLSingleSelect,
} from "../../basic-elements"
import {
	NormalColumns,
	RepAndArchColumns,
	UnarchColumns,
	RemindColumns,
	ForceArchivedTableColumns,
	AMForceArchivedColumns,
} from "./projects-table-columns"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { StyledHeader } from "../../../screens/organization-side/org-setup/org-setup-users/sub-components/OrgSetupUserListTable"
import { IdType, idToNumber } from "../../../library/converters/id-converter"
import { useHistory } from "react-router-dom"
import { ActionStatus } from "@datalobby/types"

export default observer(function SharedProjectTableV8({
	partialStore,
	projScreenType,
	menuId,
	i18n,
	projectStatus,
	permission,
	hasDueInFilter = false,
}: {
	partialStore: any
	projScreenType: ProjScreenType
	menuId: DLOrgSubMenus
	i18n: DLI18nProps
	projectStatus: ProjectStatus
	permission: PermissionAsObjectProps
	hasDueInFilter?: boolean
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const dntFormat = store.global.getDntFormat
	let history = useHistory()

	useEffect(() => {
		partialStore.setSelectedProj("")
	}, [menuId])

	// for deadline shortcuts
	const [filterType, setFilterType] = useState("")
	const [filterDays, setFilterDays] = useState("7")
	//
	const [dueIn, setDueIn] = useState("all")

	const isArchiveAdmin = orgStore.checkin.orgInfo.isArchiveAdmin

	const isAssignedOnly =
		menuId === DLOrgSubMenus.assignedNormal ||
		menuId === DLOrgSubMenus.assignedReplica ||
		menuId === DLOrgSubMenus.assignedArchived ||
		menuId === DLOrgSubMenus.assignedUnarchived

	const isRemindProjects =
		menuId === DLOrgSubMenus.remind_report ||
		menuId === DLOrgSubMenus.remind_archiving ||
		menuId === DLOrgSubMenus.admin_remind_report ||
		menuId === DLOrgSubMenus.admin_remind_archiving ||
		menuId === DLOrgSubMenus.arc_rem_norm_projs

	const hasYearFilter =
		isRemindProjects ||
		menuId === DLOrgSubMenus.normal_projs ||
		menuId === DLOrgSubMenus.assignedNormal

	const handleOpenCreateProjectDialog = () => {
		partialStore.setCreateProjectMethod(CreateProjectMethod.byUndefined)
		partialStore.setCreateProjectPresetInputs(undefined)
		partialStore.setOpenCreateProjDialog(true)
	}

	const handleYearChange = (year: string) => {
		if (menuId === DLOrgSubMenus.unarchived_mgmt) {
			partialStore.getUnarchivedProjects({ year })
		} else if (menuId === DLOrgSubMenus.archived_mgmt) {
			partialStore.getArchivedProjects({ year })
		} else if (menuId === DLOrgSubMenus.am_force_archived) {
			partialStore.getArchivedProjects({ year, usage: 3 })
		} else if (isRemindProjects) {
			partialStore.getRemindProjectList({
				projStatus: projectStatus,
				year,
				PTMAssignedOnly: menuId === DLOrgSubMenus.remind_archiving,
				menuId,
				dntFormat,
			})
		} else {
			partialStore.getOrgProjectList({
				projStatus: projectStatus,
				year,
				PTMAssignedOnly:
					isAssignedOnly ||
					menuId === DLOrgSubMenus.assignedForceArchivedProjects,
				menuId,
				ForROAM:
					menuId === DLOrgSubMenus.assignedArchivedReadonlyProjects,
				dntFormat,
			})
		}
	}

	const handleSearch = (searchText: string, year: string) => {
		if (menuId === DLOrgSubMenus.archived_mgmt) {
			partialStore.getArchivedProjects({ year, searchText })
		} else if (menuId === DLOrgSubMenus.unarchived_mgmt) {
			partialStore.getUnarchivedProjects({ year, searchText })
		} else if (menuId === DLOrgSubMenus.am_force_archived) {
			partialStore.getArchivedProjects({ year, searchText, usage: 3 })
		} else if (isRemindProjects) {
			partialStore.getRemindProjectList({
				projStatus: projectStatus,
				year,
				searchText,
				PTMAssignedOnly: menuId === DLOrgSubMenus.remind_archiving,
				menuId,
				dntFormat,
			})
		} else {
			partialStore.getOrgProjectList({
				projStatus: projectStatus,
				year,
				searchText,
				PTMAssignedOnly:
					isAssignedOnly ||
					menuId === DLOrgSubMenus.assignedForceArchivedProjects,
				menuId,
				ForROAM:
					menuId === DLOrgSubMenus.assignedArchivedReadonlyProjects,
				dntFormat,
			})
		}
	}

	const handleContextMenu = useCallback((event: any, projInfo: any) => {
		// if (
		// 	!(
		// 		menuId === DLOrgSubMenus.remind_report ||
		// 		menuId === DLOrgSubMenus.remind_archiving ||
		// 		menuId === DLOrgSubMenus.admin_remind_report
		// 	)
		// ) {
		event.preventDefault()
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
		partialStore.setSelectedProj(projInfo.id)
		partialStore.setSelectedCabinetId(projInfo.cabinetId)
		if (
			menuId === DLOrgSubMenus.normal_projs ||
			menuId === DLOrgSubMenus.replicas ||
			menuId === DLOrgSubMenus.assignedNormal ||
			menuId === DLOrgSubMenus.assignedReplica
		) {
			const projectInfo = {
				...initialCreateProjectInputs,
				// cabinetId: projInfo.cabinetId,
				// cabinetName: "loading...",
				// title: projInfo.title,
				// periodName: projInfo.periodName,
				// clientName: projInfo.clientName,
				// engTypeName: projInfo.engTypeName,
				createMethod: CreateProjectMethod.byRollForward,
			}
			partialStore.setRFSourceProjInfo({
				groupName: projInfo.groupName,
				cabinetName: projInfo.cabinetName,
				clientName: projInfo.clientName,
				clientAliasId: projInfo.clientAliasId,
				title: projInfo.title,
				projectAliasId: projInfo.aliasId,
				version: projInfo.version,
				periodName: projInfo.periodName,
				financialYear: projInfo.financialYear,
				periodEndDate: projInfo.periodEndDate,
			})
			partialStore.setSelectedItem({ id: projInfo.id })
			partialStore.setCreateProjectPresetInputs(projectInfo)
			partialStore.setRfSourceId(projInfo.id)
		}
		// }
	}, [])

	const epList = partialStore.viewEPUsers(projScreenType)
	const qcList = partialStore.viewQCList(projScreenType)
	const userRole = orgStore.checkin.assignedRole.shortName

	const projId = partialStore.selectedProj
	const projNumId = idToNumber(projId, IdType.project)
	const projectInfo = partialStore.viewSelectedProjInfo(projId, projectStatus)

	const archiveActionMenus = [
		{
			label: "Open",
			value: "open",
			disabled: !projectInfo?.accessible || !projId,
			available: projectInfo?.accessible,
			clickEvent: () => history.push(`/project/checkin/${projNumId}`),
		},
		{
			label: `${i18n.tsReadOnlyAccessManagement}`,
			value: "ctx-read_only_access_management",
			available: permission.update,
			disabled: !projId,
			clickEvent: () =>
				orgStore.archiveMgmt.setAccessMgmtDialogOpen(true),
		},
		{
			label: `${i18n.tsExportArchivedZipFile}`,
			value: "ctx-export_archived_zip_file",
			available: permission.export,
			// disabled: !projectInfo?.hasExportPermission || !projId,
			clickEvent: () =>
				orgStore.archiveMgmt.setArchiveZipRegenerationDialog(true),
		},
		{
			label: `${i18n.tsRepairReplica}`,
			value: "ctx-repair_broken_replica",
			available: permission.create,
			// disabled: !projectInfo?.hasExportPermission || !projId,
			clickEvent: () => orgStore.archiveMgmt.setRepairReplicaDialog(true),
		},
		{
			label: `${i18n.twDigitalSignature}`,
			value: "ctx-digital_signature",
			available: permission.read,
			disabled: !projId,
			clickEvent: () =>
				orgStore.archiveMgmt.setDigitalSignatureDialogOpen(true),
		},
		{
			label: `${i18n.twUnarchive}`,
			value: "ctx-unarchive",
			available: permission.update,
			disabled: !projId,
			clickEvent: () => {
				const actionName = "unarchiveProject"
				orgStore.archiveMgmt.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.standby,
				})
				orgStore.archiveMgmt.setArchivedProjectUnarchiveDialogOpen(true)
			},
		},
		{
			label: `${i18n.twDelete}`,
			value: "ctx-proj_delete", // "ctx-delete",
			available: permission.delete,
			disabled: !projId,
			clickEvent: () =>
				orgStore.archiveMgmt.setRemoveArchivedProjectDialogOpen(true),
		},
	]

	const archiveInfoMenus = [
		{
			label: "Project Information", // `${i18n.twProjectDetails}`,
			value: "ctx-proj_info", // "ctx-details",
			available: permission.read,
			disabled: !projId,
			clickEvent: () =>
				orgStore.archiveMgmt.setProjectDetailsDialogOpen(true),
		},
		{
			label: "Pre-Process PDFs",
			value: "ctx-pre_process_pdf",
			available: permission.create,
			// disabled: !projectInfo?.hasExportPermission || !projId,
			clickEvent: () => orgStore.archiveMgmt.setPreProcessDialog(true),
		},
	]

	const unarchiveActionMenus = [
		{
			label: "Open",
			value: "open",
			disabled: !projectInfo?.accessible || !projId,
			available: projectInfo?.accessible,
			clickEvent: () => history.push(`/project/checkin/${projNumId}`),
		},
		{
			label: `${i18n.twDelete}`,
			value: "ctx-proj_delete", // "ctx-delete",
			available: permission.delete,
			disabled: !projId,
			clickEvent: () =>
				orgStore.unarchiveMgmt.setOpenDeleteProjDialog(true),
		},
	]

	const amForceArchiveActionMenu = [
		{
			label: "Open",
			value: "open",
			disabled: !projId,
			// available: projectInfo?.accessible,
			clickEvent: () => history.push(`/project/checkin/${projNumId}`),
		},
		{
			label: "Release Force-Archived",
			value: "release-force-archive",
			available: permission.read,
			disabled: !projId,
			clickEvent: () =>
				orgStore.archiveMgmt.setReleaseForceArchiveDialog(true),
		},
		{
			label: `${i18n.twDelete}`,
			value: "ctx-proj_delete", // "ctx-delete",
			available: permission.delete,
			disabled: !projId,
			clickEvent: () =>
				orgStore.archiveMgmt.setRemoveArchivedProjectDialogOpen(true),
		},
	]

	const adminRemindActionMenu = [
		{
			label: "Open",
			value: "open",
			disabled: !projId,
			clickEvent: () => history.push(`/project/checkin/${projNumId}`),
		},
		{
			label: "Force Archive",
			value: "ctx-force-archive",
			disabled: !projId,
			available:
				permission.read &&
				menuId === DLOrgSubMenus.admin_remind_archiving,
			clickEvent: () => orgStore.projects.setForceArchiveDialog(true),
		},
	]

	const pageHeader = (table: Table<any>) => {
		const totalProjects = table.getPreFilteredRowModel().flatRows.length
		const filteredProjects = table.getFilteredRowModel().flatRows.length
		const currentYear = new Date().getFullYear()
		const filteredYear: any = table
			.getState()
			.columnFilters?.find(
				(item: any) => item.id === "financialYear"
			)?.value

		const financialYear = filteredYear
			? filteredYear
			: hasYearFilter
			? "All Years"
			: currentYear.toString()

		const handleType = (value: any) => {
			setFilterType(value)
			handlePeriodFilter(value, filterDays)
		}
		const handleDays = (value: any) => {
			setFilterDays(value)
			handlePeriodFilter(filterType, value)
		}

		const handlePeriodFilter = (type: string, days: string) => {
			if (type) {
				const toDate = addDays(new Date(), +days)
				const formattedFromDate = format(
					new Date(),
					dntFormat.dateFormat
				)
				const formattedToDate = format(toDate, dntFormat.dateFormat)
				// onChangeDate(type, {startDate: formattedFromDate, endDate: formattedToDate})
				table.setColumnFilters((props: any) => {
					const filteredProps = props.filter(
						(item: any) => item.id === type
					)
					console.log(filteredProps)
					return [
						...filteredProps,
						{
							id: type,
							value: {
								startDate: formattedFromDate,
								endDate: formattedToDate,
							},
						},
					]
				})
			}
		}

		return (
			<StyledHeader style={{ width: "100%" }}>
				<div className="FR">
					<DLContextMenuWithButton
						eleTestId="folders-btn"
						menuOptions={
							menuId === DLOrgSubMenus.archived_mgmt
								? archiveActionMenus
								: menuId === DLOrgSubMenus.unarchived_mgmt
								? unarchiveActionMenus
								: menuId === DLOrgSubMenus.am_force_archived
								? amForceArchiveActionMenu
								: isRemindProjects
								? adminRemindActionMenu
								: [
										{
											label: "Open",
											value: "open",
											disabled:
												!projectInfo?.accessible ||
												!projId,
											available:
												projectInfo?.accessible &&
												permission.read,
											clickEvent: () =>
												history.push(
													`/project/checkin/${projNumId}`
												),
										},

										{
											label: "Export Archived Zip File",
											value: "ctx-export_archived_zip_file",
											available:
												projectStatus ===
													ProjectStatus.roamArchived &&
												permission.export,
											disabled:
												!projectInfo?.hasExportPermission ||
												!projectInfo?.accessible ||
												!projId,
											clickEvent: () =>
												orgStore.archiveMgmt.setArchiveZipRegenerationDialog(
													true
												),
										},
										{
											label: "Roll Forward",
											value: "ctx-roll_forward",
											disabled: !projId,
											// NOTE: Arch mgmt use different ctx menu... (AM UnArch uses this basic ctx menu)
											// TODO: Is this right method? Isn't it better to use same ctx menu on the all proejcts screen?
											// disabled: projStatus !== ProjectStatus.archived,
											// WARNING: Only the user who has roll-forward permission can do that (also SA and AA)
											// NOTE: what happens if the user doesn't have the roll forward permission?
											available:
												(projectStatus ===
													ProjectStatus.normal ||
													projectStatus ===
														ProjectStatus.replica) &&
												permission.create,
											clickEvent: () => {
												partialStore.setCreateProjectMethod(
													CreateProjectMethod.byRollForward
												)
												// TODO: ProjectStatus part should be updated
												// WARNING:

												partialStore.setOpenCreateProjDialog(
													true
												)
											},
										},
										{
											label: "Move",
											value: "ctx-proj_move",
											disabled: !projId,
											// WARNING: Project archive admin & project admin can do this (also SA and AA)
											available:
												!isAssignedOnly &&
												(userRole === "AA" ||
													userRole === "SA") &&
												projectStatus !==
													ProjectStatus.archived &&
												projectStatus !==
													ProjectStatus.unarchived &&
												projectStatus !==
													ProjectStatus.forceArchived &&
												projectStatus !==
													ProjectStatus.roamArchived &&
												permission.move,

											// WARNING: (TBD) This moving feature will be helpful if the user lost its parent cabinet...
											clickEvent: () => {
												orgStore.groups.initializeStore()
												orgStore.groups.getOrgGroupList(
													orgStore.checkin.orgInfo
														.userId,
													() => {
														orgStore.groups.getOrgCabinetDetail(
															projectInfo.cabinetId
														)
														orgStore.groups.setOpenMoveDialog(
															true
														)
													}
												)
											},
										},
										{
											label: "Delete",
											value: "ctx-proj_delete",
											disabled: !projId,
											// WARNING: Only the project archive admin can delete the project (except the SA and AA)
											available:
												!isAssignedOnly &&
												(userRole === "AA" ||
													userRole === "SA") &&
												projectStatus !==
													ProjectStatus.archived &&
												projectStatus !==
													ProjectStatus.unarchived &&
												projectStatus !==
													ProjectStatus.forceArchived &&
												projectStatus !==
													ProjectStatus.roamArchived &&
												permission.delete,

											clickEvent: () =>
												partialStore.setOpenDeleteProjDialog(
													true
												),
										},
								  ]
						}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Item Actions"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>

					<DLContextMenuWithButton
						eleTestId="folders-btn"
						menuOptions={
							menuId === DLOrgSubMenus.archived_mgmt
								? archiveInfoMenus
								: [
										{
											label: "Project Information",
											value: "ctx-proj_info",
											available: permission.read,
											disabled: !projId,
											clickEvent: () =>
												partialStore.setProjectDetailsDialogOpen(
													true
												),
										},
										{
											label: "Cabinet Info",
											value: "cabinet_info",
											available:
												permission.read &&
												projectStatus !==
													ProjectStatus.forceArchived &&
												!isRemindProjects,
											disabled: !projId,
											clickEvent: () =>
												partialStore.setOpenProjLocationDialog(
													true
												),
										},
										{
											label: "Pre-Process PDFs",
											value: "ctx-pre_process_pdf",
											available:
												projectStatus ===
													ProjectStatus.replica &&
												permission.read,
											disabled: !projId,
											clickEvent: () =>
												partialStore.setPreProcessDialog(
													true
												),
										},
								  ]
						}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Item Info"
						btnVariant="outlined"
						btnColor="primary"
						btnSize="regular"
						btnClassName="ctx-menu-styles ml-8"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>

				<div style={{ marginTop: 10 }}>
					<SharedProjectsPageHeader
						year={financialYear}
						projectStatus={projectStatus}
						projLength={totalProjects}
						filteredProjLength={filteredProjects}
						clearFilters={table.resetColumnFilters}
						additionalButtons={
							menuId === DLOrgSubMenus.normal_projs && (
								<div className="FR AC">
									{permission.create && (
										<DLButton
											variant="text"
											color="primary"
											eleTestId="add-new-proj-btn"
											startIcon={
												<Icon path={mdiPlus} size={1} />
											}
											clickHandler={
												handleOpenCreateProjectDialog
											}
										>
											{i18n.twNewProject ||
												"Create Project"}
										</DLButton>
									)}
								</div>
							)
						}
						menuId={menuId}
						shortcuts={
							<>
								{projectStatus === ProjectStatus.normal &&
									!isRemindProjects && (
										<StyledDeadlineShortcut className="FR AC deadline-filter-container">
											<span className="partition">|</span>
											<DLSingleSelect
												eleValue={filterType}
												eleOnChange={(e) =>
													handleType(e.target.value)
												}
												optionList={[
													{
														name: "Expected Report Date",
														value: "expectedReportDate",
													},
													{
														name: "Expected Archive Deadline Date",
														value: "expectedArchiveDate",
													},
													{
														name: "Final Archive Deadline Date",
														value: "finalArchiveDeadlineDate",
													},
												]}
												eleTestId="shortcut-deadline-type-select"
												eleClassName="deadline-type-select"
												placeholder="Deadline Type"
												withLabel={false}
											/>
											<span>in</span>
											<DLInputField
												eleType="number"
												eleTestId="shortcut-days"
												eleClassName="shortcut-days"
												eleValue={filterDays}
												eleFullWidth={false}
												eleHandleChange={(e: any) =>
													handleDays(e.target.value)
												}
											/>
											<span>days</span>
										</StyledDeadlineShortcut>
									)}
								{hasDueInFilter && (
									<div className="FR AC">
										<span className="partition">|</span>
										<DLSingleSelect
											eleValue={dueIn}
											eleOnChange={(e) => {
												setDueIn(e.target.value)
												table.resetPagination()
											}}
											optionList={[
												{
													name: "Show All",
													value: "all",
												},
												{
													name: "Over Due",
													value: "overDue",
												},
												{
													name: "Due in a week",
													value: "inAWeek",
												},
												{
													name: "Due in 2 weeks",
													value: "in2Weeks",
												},
											]}
											eleTestId="due-in-select"
											eleClassName="due-in-select"
											placeholder="Select Due In"
											withLabel={false}
										/>
									</div>
								)}
							</>
						}
						canSearch={!isRemindProjects}
						handleSearch={(searchText: string) =>
							handleSearch(searchText, financialYear)
						}
					/>
				</div>
			</StyledHeader>
		)
	}

	console.time("Timea")
	let data = useMemo(() => {
		if (isRemindProjects) {
			if (dueIn === "all") {
				return partialStore.formattedProjList(
					dntFormat,
					projScreenType,
					menuId
				)
			} else {
				return partialStore
					.formattedProjList(dntFormat, projScreenType, menuId)
					.filter((item: any) => {
						if (dueIn === "overDue") {
							return item.dueDays < 0
						} else if (dueIn === "inAWeek") {
							return item.dueDays <= 7 && item.dueDays > 0
						} else if (dueIn === "in2Weeks") {
							return item.dueDays <= 14 && item.dueDays > 0
						} else {
							return true
						}
					})
			}
		} else {
			return partialStore.formattedProjList(
				dntFormat,
				projScreenType,
				menuId
			)
		}
	}, [
		dueIn,
		partialStore.formattedProjList(dntFormat, projScreenType, menuId),
	])
	console.timeEnd("Timea")
	/**
	 * * Column types
	 * - A: for Normal projects
	 * - B: for Replica & Archived projects
	 * - C: for Unarchived projects
	 * - D: for recent projects
	 * - E: for dashboard
	 *
	 * * Projects table in
	 * - My Page
	 * --- Recent Proejcts
	 * --- Remind Report _________ D
	 * --- Remind Archiving ______ D
	 * - Assigned Projects
	 * --- Normal ________________ A
	 * --- Replica _______________ B
	 * --- Archived ______________ B
	 * --- Unarchived ____________ C
	 * --- ROAM Archived _________ B
	 * - Org Dashboard
	 * --- Table View ____________ E
	 * - All Projects
	 * --- Normal ________________ A
	 * --- Replica _______________ B
	 * --- Archived ______________ B
	 * --- Unarchived ____________ C
	 * - Archive Management
	 * --- Archived ______________ B
	 * --- Unarchived ____________ C
	 */

	// B - the most uses

	const handleCheckbox = useCallback((id: string) => {
		partialStore.setSelectedProj(id)
	}, [])

	let tableColumns: any = RepAndArchColumns(
		partialStore,
		handleContextMenu,
		handleCheckbox,
		partialStore.selectedProj
	)
	// A
	if (
		menuId === DLOrgSubMenus.assignedNormal ||
		menuId === DLOrgSubMenus.normal_projs
	) {
		tableColumns = NormalColumns(
			partialStore,
			handleContextMenu,
			handleCheckbox
		)
	}
	// D
	if (isRemindProjects) {
		tableColumns = RemindColumns(
			isArchiveAdmin,
			menuId,
			partialStore,
			handleContextMenu,
			handleCheckbox
		)
	}
	// C
	if (
		menuId === DLOrgSubMenus.assignedUnarchived ||
		menuId === DLOrgSubMenus.unarchived ||
		menuId === DLOrgSubMenus.unarchived_mgmt
	) {
		tableColumns = UnarchColumns(
			partialStore,
			handleContextMenu,
			handleCheckbox
		)
	}

	if (
		menuId === DLOrgSubMenus.assignedForceArchivedProjects ||
		menuId === DLOrgSubMenus.force_archived
	) {
		tableColumns = ForceArchivedTableColumns(
			partialStore,
			handleContextMenu,
			permission.read,
			handleCheckbox
		)
	}

	if (menuId === DLOrgSubMenus.am_force_archived) {
		tableColumns = AMForceArchivedColumns(
			partialStore,
			handleContextMenu,
			permission.read,
			handleCheckbox
		)
	}

	return (
		<StyledSharedProjectTableV8>
			<ReactTableV8
				tableColumns={tableColumns}
				data={data}
				hasPagination={true}
				customFilters={(props: any) =>
					filterComponent({
						...props,
						dateFormat: dntFormat.dateFormat,
						handleYearChange,
						epList,
						qcList,
						hasYearFilter,
					})
				}
				handleContextMenu={handleContextMenu}
				height={store.ui.contentsAreaHeight - 50}
				pageHeader={pageHeader}
				i18n={i18n}
				menuId={menuId}
				permission={permission}
				onRowClick={(event: any, projInfo: any) => {
					partialStore.setSelectedProj(projInfo.id)
					partialStore.setSelectedCabinetId(projInfo.cabinetId)
				}}
			/>
		</StyledSharedProjectTableV8>
	)
})

const StyledSharedProjectTableV8 = styled.div`
	table > thead > tr > th {
		padding: 0;
		.header {
			width: inherit;
			min-height: 2rem;
			/* padding-left: 8px; */
			padding-right: 18px;
			padding-left: 5px;
			justify-content: center;
			font-size: 12px;
			line-height: 14px;
			.column-name {
				overflow: hidden;
			}
			input {
				font-size: 14px;
			}
		}
	}
	table > tbody > tr > td {
		padding: 0 8px 0 8px;
	}
	table > tbody > tr > td.dueDays {
		padding: 0;
		margin-right: 10px;
		&.dueDays-overdue {
			background-color: darkred;
			color: white;
		}
		&.dueDays-today {
			background-color: red;
			color: white;
		}
		&.dueDays-1week {
			background-color: yellow;
		}
		&.dueDays-2weeks {
			background-color: orange;
		}
		&.dueDays-grayout {
			opacity: 0.3;
		}
	}
	.archive-orange {
		color: #fea621 !important;
		a {
			color: #fea621 !important;
		}
	}
`

const filterComponent = ({
	column,
	table,
	dateFormat,
	handleYearChange,
	epList,
	qcList,
	hasYearFilter,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
	handleYearChange: any
	epList: any
	qcList: any
	hasYearFilter: boolean
}) => {
	switch (column.id) {
		case "periodName":
		case "clientName":
		case "groupName":
		case "engTypeName":
			return (
				<DLComboBoxWithCheckbox
					options={[...column.getFacetedUniqueValues().keys()]}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						column.setFilterValue(value)
					}}
					eleValue={(column.getFilterValue() ?? []) as string[]}
					eleTestId="periodName-select"
				/>
			)

		case "epUsers":
		case "qcUsers":
			return (
				<DLComboBoxWithCheckbox
					options={column.id === "epUsers" ? epList : qcList}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						column.setFilterValue(value)
					}}
					eleValue={(column.getFilterValue() ?? []) as string[]}
					eleTestId={`${column.id}-select`}
				/>
			)

		case "archivedAt":
		case "unarchivedAt":
		case "periodEndDate":
		case "finalReportDate":
		case "expectedReportDate":
		case "expectedArchiveDate":
		case "finalArchiveDeadlineDate":
		case "lastAccessDate":
		case "expectedArchiveDate1":
		case "finalReportDate2":
		case "finalArchiveDeadlineDate1":
		case "finalReportDate1":
		case "archiveDatePolicy1":
		case "expectedArchiveDate2":
		case "expectedReportDate1":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "financialYear":
			return (
				<DLYearpicker
					eleTestId="financial-year-select"
					eleName="financialYear"
					selected={
						column.getFilterValue()
							? column.getFilterValue()
							: hasYearFilter
							? "All Years"
							: new Date().getFullYear()
					}
					onChange={(value: any) => {
						column.setFilterValue(value)
						handleYearChange(value)
					}}
					showClearIcon
				/>
			)

		case "lockStatus":
			return (
				<select
					onChange={(event: any) =>
						column.setFilterValue(event.target.value)
					}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="lockStatus-select"
				>
					<option value="all">All</option>
					<option value="locked">Locked</option>
					<option value="unlocked">Unlocked</option>
				</select>
			)

		case "replicaStatus":
		case "archiveZipStatus":
			return (
				<select
					onChange={(event: any) =>
						column.setFilterValue(event.target.value)
					}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid={`${column.id}-select`}
				>
					<option value="all">All</option>
					<option value="true">Yes</option>
					<option value="false">-</option>
				</select>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const StyledDeadlineShortcut = styled.div`
	&.deadline-filter-container {
		.deadline-type-select {
			min-height: 0px !important;
		}
		span {
			margin: 0 0.6rem;
		}
		.shortcut-days {
			min-width: 1rem;
			max-width: 2rem;
			input {
				font-size: ${(props) => props.theme.shared.textLg};
			}
		}
	}
	.partition {
		margin: 0 1rem;
		color: lightgray;
	}
`
