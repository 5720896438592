import React, { useCallback, useState } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import {
	DLDialog,
	DLSpinnerCenterAligned,
	DLTextArea,
	InputWithLabel,
} from "../../basic-elements"
import styled from "styled-components"
import {
	ActionStatus,
	DLSector,
} from "../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { CommentModelProps } from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import CommentTargetObjectInfo from "./CommentTargetObjectInfo"
import { DLObjectPropsForComment } from "../../../common-models/types/comment-props"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

export default observer(function CommentCloseDialog({
	partialStore,
	commentId,
}: {
	partialStore: any
	commentId: string
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const commentStore = projStore.comments
	const initialInputs = {
		response: "",
	}
	const [inputs, setInputs] = useState<any>(initialInputs)

	const setOpen = (request: boolean) => {
		partialStore.setCommentClosingDialogOpenStatus(request)
	}

	const dntFormat = store.global.getDntFormat

	const commentInfo = commentStore.viewCommentById(commentId, dntFormat)
	const objFromCommentStore = commentStore.getObjectInfo(commentId)
	const projId = projStore.checkin.projId
	console.log(commentInfo, "commentInfo")
	const objectInfo = {
		sector: DLSector.proj,
		sectorId: projId,
		belongsTo:
			objFromCommentStore?.menuId === DLProjSubMenus.wp
				? DLProjSubMenus.wp
				: DLProjSubMenus.pf,
		parentId: "",
		parentTitle: "",
		//
		id: objFromCommentStore?.id,
		title: objFromCommentStore?.title,
		// format: objFromCommentStore?.srcFormat, // TODO:
		format: undefined, // TODO:
		extension: "",
		//
		aliasId: objFromCommentStore?.aliasId,
	}

	const handleInputs = useCallback((event: any) => {
		const name = event.target.name
		let value = event.target.value
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	const menuId = commentId.includes("wp")
		? DLProjSubMenus.wp
		: commentId.includes("pf")
		? DLProjSubMenus.pf
		: ""

	const userName = projStore.checkin.user.name

	const handleAction = () => {
		if (menuId !== "") {
			commentStore.closeComment({
				inputs: { ...inputs, id: commentId, closedBy: userName },
				menuId,
				postWorkForSuccess: () => {
					partialStore.setCommentClosingDialogOpenStatus(false)
					projStore.comments.getCommentsByObj({
						objectId: partialStore.selectedFile,
						menuId,
					})
				},
				handleResponse: partialStore.handleResponse,
			})
		} else {
			alert(`Sorry, cannot find matched menuId for ${commentId}`)
		}
	}

	const isInProgress =
		commentStore.getActionStatus("closeComment") === ActionStatus.loading
	return (
		<DLDialog
			eleTestId="close-comment-dialog"
			isOpen={partialStore.commentClosingDialogOpenStatus}
			setIsOpen={setOpen}
			dialogTitle="Close Review Comment"
			dialogContents={
				<>
					{isInProgress && (
						<DLSpinnerCenterAligned
							absolute
							backgroundOpacity={0.5}
						/>
					)}
					<CommentCloseForm
						commentInfo={commentInfo}
						inputs={inputs}
						handleInputs={handleInputs}
						objectInfo={objectInfo}
					/>
				</>
			}
			actionReady={inputs.response !== "" && !isInProgress}
			handleAction={handleAction}
			showCloseBtn
			actionBtn="Mark Closed"
			cancelBtnText="Cancel"
		/>
	)
})

const CommentCloseForm = ({
	commentInfo,
	inputs,
	handleInputs,
	objectInfo,
}: {
	commentInfo: CommentModelProps
	inputs: any
	handleInputs: any
	objectInfo: DLObjectPropsForComment
}) => {
	return (
		<StyledCommentCloseForm>
			<div className="input-section FR">
				<InputWithLabel label="Comment On">
					<CommentTargetObjectInfo object={objectInfo} />
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel
					label="Comment Info"
					data-testid="comment-info-in-closing-form"
				>
					<div>Assignee: {commentInfo.assigneeNames?.join(",")}</div>
					<div>Comment: {commentInfo.contents}</div>
					<div>Created By: {commentInfo.createdBy.name}</div>
					<div>Created At: {commentInfo.createdAt}</div>
				</InputWithLabel>
			</div>

			<div className="input-section FR">
				<InputWithLabel label="Response" required>
					<DLTextArea
						eleTestId="input-response-text"
						elePlaceholder="Please input response"
						eleValue={inputs.response}
						eleHandleChange={handleInputs}
						eleName="response"
						eleClassName="Response-text-input-area"
					/>
				</InputWithLabel>
			</div>
		</StyledCommentCloseForm>
	)
}

const StyledCommentCloseForm = styled.div`
    .input-section {
        margin-bottom: 1.5rem;
        .label {
            min-width: 9rem;
        }
        .input-area {
            /* width: calc(100% - 9rem); */
            min-width: 20rem;
        }
        .comment-text-input-area {
            border: 1px solid ${(props) => props.theme.shade20};
            border-radius: 3px;
            transition: 0.4s;
            padding: 1rem;
            .MuiInput-underline:before {
                border-bottom: none;
                /* TODO: update is required */
            }
            .MuiInput-underline:after {
                border-bottom: none;
                /* TODO: update is required */
            }
        }
        /* .comment-text-input-area:focus {
            border: 1px solid red;
        } */
`
