import React from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
//
import { OldSignOffType } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import {
	ActionStatus,
	DLSector,
	DialogActionType,
	DLSrcFormat,
	DLCommentType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	DLIconName,
	DLIcon,
	CommonIconSize,
	IconPlaceholder,
	//
	InfoIcon,
	PrepareIcon,
	ReviewIcon,
	FileCheckoutIcon,
	RequestIcon,
	MoveIcon,
} from "../../../../../components/basic-elements/icons/common-icons"
import { HandleResponseProps } from "../../../../../common-models/common-view-model-actions"
import { DLFileProps } from "../../../../../service-modules/file-module/data-models/file.data-model"
import {
	FileMenuViewType,
	FileScreenDialog,
	SelectedFileItemProps,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { checkFileProps } from "../../../../../service-modules/file-module/check-file-props-to-open"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../../temporary-data/project-side/signoff-policy"
import { useHistory } from "react-router-dom"
import {
	IdType,
	idToNumber,
} from "../../../../../library/converters/id-converter"

const FileContextMenuOptions = ({
	partialStore,
	fileInfo,
	handleClose,
	permission,
	commentPermission,
}: {
	partialStore: {
		selectedItems: SelectedFileItemProps[]
		selectedFile: string
		[x: string]: any
	}
	fileInfo: DLFileProps
	handleClose: () => void
	permission: PermissionAsObjectProps
	commentPermission: PermissionAsObjectProps
}) => {
	const store = useRootStore()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	const menuId = partialStore.storeName
	const history = useHistory()
	//
	const selectedItems = partialStore.selectedItems // TODO: menuType should be catched...
	//
	const checkAvailableSignOffs = partialStore.checkAvailableSignOffs()
	//
	const setFileTreeDialogOpen = partialStore.setFileTreeDialogOpen
	const handleResponse = partialStore.handleResponse
	const itemsCount = selectedItems.length

	const userId = projStore.checkin.userId
	const userRole = projStore.checkin.user.roleId
	// console.log("assignedRole, ", projStore.checkin.assignedRole)
	// console.log("userRole, ", userRole)
	console.log("role 2,", projStore.checkin.user.roleId)
	const userName = localStorage.getItem("orgUserName")
	const openedByName = fileInfo.openedByName

	const projId = projStore.checkin.projId

	const roleId = projStore.checkin.user.roleId
	const isReadonly = projStore.checkin.user.isReadonly
	const userType = projStore.checkin.user.userType
	const structureLock = partialStore.structureLock

	const signOffPolicy =
		menuId === DLProjSubMenus.wp
			? DefaultSignOffPolicy
			: menuId === DLProjSubMenus.pf
			? PfSignOffPolicy
			: []

	/**
	 * 1. open
	 * 2. checkout
	 * 3. checkin
	 * 4. checkout to local
	 * 5. checkin from local
	 * 6. prepare
	 * 7. unprepare
	 * 8. Review
	 * 9. unreview
	 * 10. sign off log
	 * 11. add comment
	 * 12. view commecnts
	 * 13. assign role
	 * 14. edit info
	 * 15. open info
	 * 16. replace
	 * 17. donwload
	 * 18. delete
	 */

	console.log(fileInfo, "fileInfo")

	const finalAnalysisLink = `${defaultUrl}/files/workpaper-files/${fileInfo.id}`
	const handleFileOpen = () => {
		const fileSize = fileInfo.size.split("K")[0]
		if (parseInt(fileSize) > 15000) {
			alert(
				"Online editor cannot open the file size over 15 MB. Please use file download and replace. To have download permission, a user must be assigned to this project."
			)
			return
		}
		if (itemsCount === 1) {
			if (fileInfo.srcFormat === DLSrcFormat.finalAnalysis) {
				const menuId = `ProjMenu_FinalAnalysis${fileInfo.title}`
				const newMenu = {
					id: menuId,
					title: `Final Analysis - ${fileInfo.title}`,
					url: finalAnalysisLink,
				}
				// NOTE: @Noah: how to control final anaylysis with DLProjSubMenus enum
				projStore.menuTabs.setCurrentMainMenu(menuId)
				projStore.menuTabs.addOpenedMenu(newMenu)
			} else {
				const userInfo = {
					id: userId,
					isReadonly,
					isExternal: userType === "external",
				}
				const orgId = localStorage.getItem("orgId") ?? "X"
				const fileProps = checkFileProps({
					orgId,
					projId,
					fileInfo,
					userInfo,
					asReadonly: false, // before update the 'open as readonly', default is 'false'
				})
				if (fileProps && fileProps.useEditor) {
					store.editor.openEditor(fileProps.props)
				} else if (fileProps && fileProps.useEditor === false) {
					partialStore.openFileWithoutEditor(fileProps?.props)
				} else {
					console.log("What should I do on here..?")
				}
			}
		} else if (itemsCount > 1) {
			alert("(handleFileOpen) cannot open multiple files")
		} else if (itemsCount <= 0) {
			alert("(handleFileOpen) No selected files")
		}
	}

	const handleSignOff = (type: OldSignOffType) => {
		partialStore.handleSignOff({
			type,
			forCancel: false,
			userId,
			roleId,
		})
	}

	const handleUnSignOff = (type: OldSignOffType) => {
		partialStore.handleSignOff({
			type,
			forCancel: true,
			userId,
			roleId,
		})
	}

	const handleFileDelete = () => {
		if (itemsCount > 1) {
			let proceed = window.confirm(
				`Do you really want to delete ${itemsCount} files?`
			)
			if (proceed) {
				partialStore.removeFiles({ fileIds: selectedItems, index: 0 })
				handleClose()
			}
		} else if (itemsCount === 1) {
			let fileId = ""
			if (!selectedItems[0].isParent) {
				fileId = selectedItems[0].id
			} else {
				alert("(FileContextMenuOptions) Selected item is not a file")
			}
			if (fileId !== "") {
				partialStore.removeFile(fileId)
				handleClose()
			}
		} else if (itemsCount <= 0) {
			alert("(FileContextMenuOptions) No selected items")
		}
	}

	const handleFileDownload = () => {
		if (itemsCount > 1) {
			// pending
		} else if (itemsCount === 1) {
			const fileId = selectedItems[0].id // TODO:
			partialStore.downloadFile(fileId)
		} else if (itemsCount <= 0) {
			// pending
		}
	}

	const handleCheckoutWithoutOpen = () => {
		const checkoutProps = {
			srcId: fileInfo.srcId,
			userId,
			sector: DLSector.proj,
			sectorId: projId,
			menuId: partialStore.storeName,
			postActionForSuccess: () =>
				partialStore.reflectFileCheckout(fileInfo.id),
		}
		store.editor.checkoutFile(checkoutProps)
	}

	const handleCheckinWithoutOpen = () => {
		store.editor.checkinFile({
			menuId,
			srcId: fileInfo.srcId,
			userId,
			sector: DLSector.proj,
			projId,
			postAction: () => {
				partialStore.reflectFileCheckin(fileInfo.id)
			},
		})
	}

	const handleDiscardCheckout = () => {
		store.editor.prepareDiscardCheckout({
			userId,
			menuId: partialStore.storeName,
			srcId: fileInfo.srcId,
			postAction: () => {
				partialStore.reflectFileCheckin(fileInfo.id)
			},
		})
	}

	const handleAssignRole = () => {
		const actionName = "assignMultipleRoleToFile"
		// NOTE: wp???? WARNING: WARNING:
		partialStore.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.standby,
		})
		setFileTreeDialogOpen(FileScreenDialog.assign, true)
	}

	const handleRequestCheckin = () => {
		const props = {
			projId,
			srcId: fileInfo.srcId,
			menuId: partialStore.storeName,
			message: "",
			userId,
		}
		const localHandleResponse = (responseProps: HandleResponseProps) => {
			handleResponse(responseProps)
		}
		store.editor.requestCheckinFile(props, localHandleResponse)
	}

	const handleOpenCommentAddDialog = (type: DLCommentType) => {
		if (itemsCount > 1) {
			alert("Cannot add comment on multiple files, please select again")
			return
		} else if (itemsCount <= 0) {
			alert("Please select a file first")
			return
		}
		// projStore.comments.setCurrentTargetObject(
		// 	partialStore.storeName,
		// 	fileId
		// )

		partialStore.setCommentDetailDialogMode(DialogActionType.add)
		partialStore.setCommentType(type)
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentDetail, true)
	}

	// const handleOpenSignOffDetailDialog = () => {
	// 	if (itemsCount > 1) {
	// 		alert("Cannot open the sign off detail dialog for multiple files")
	// 		return
	// 	} else if (itemsCount <= 0) {
	// 		alert("Please select a file first")
	// 		return
	// 	} else {
	// 		partialStore.setFileTreeDialogOpen(
	// 			FileScreenDialog.signOffDetail,
	// 			true
	// 		)
	// 	}
	// }

	const handleOpenCommentListOfFile = () => {
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentList, true)
	}

	const handleFetchFileLocation = () => {
		if (menuId === DLProjSubMenus.wp) {
			projStore.wp.refresh()
		} else {
			projStore.pf.refresh()
		}
		projStore.wp.getFileLocation({
			projId: projId,
			fileId: idToNumber(fileInfo?.srcId, IdType.src).toString(),
			fileType: DLProjSubMenus.wp.toLowerCase(),
			postSuccess: (data: any) => {
				const folderId = data.folderLocation.find(
					(item: any) => item.folderDepth === 0
				)?.mediaFolderId
				if (menuId === DLProjSubMenus.wp) {
					history.push(defaultUrl + "/files/workpaper-files", {
						folderId,
					})
				} else {
					history.push(defaultUrl + "/files/permanent-files", {
						folderId,
					})
				}
			},
		})
	}

	/**
	 * step 1. checkout
	 * step 2. download
	 */
	// const handleCheckoutToLocal = () => {
	// 	// step 1. checkout
	// 	const checkoutProps = {
	// 		srcId: fileInfo.srcId,
	// 		userId,
	// 		sector: DLSector.proj,
	// 		sectorId: projId,
	// 		menuId: partialStore.storeName,
	// 		postActionForSuccess: () => {
	// 			const fileId = fileInfo.id
	// 			partialStore.reflectFileCheckout(fileId)
	// 			// step 2.
	// 			partialStore.downloadFile(fileId)
	// 		},
	// 	}
	// 	store.editor.checkoutFile(checkoutProps)
	// }

	// const handleCheckinFromLocal = () => {}

	// ----- i18n -----
	// WARNING: Because the file ctx menu can be shared globally,
	// set the file related words on the global sheet
	const i18n = store.i18n.globalI18n("file-ctx-menu-options")

	const ableToPrepare = signOffPolicy[0].availableRoles.includes(userRole)
	const ableToReview = signOffPolicy[1].availableRoles.includes(userRole)

	const projRole = projStore.checkin.user.roleShortName

	const selectedFiles = partialStore.selectedFiles
	// const objInfo = partialStore.getItemInfo(selectedItems[0])

	const isSignOffLocked = selectedFiles.some((itemId: any) =>
		partialStore.flatList.find(
			(obj: any) => obj.id === itemId && obj.signOffLock === true
		)
	)
	const isLocked = projStore.projInfo.projInfo.isLocked

	const isReplaceAvailable = [
		DLSrcFormat.finalAnalysis,
		DLSrcFormat.fluxAnalysis,
		DLSrcFormat.leadSheet,
		DLSrcFormat.auditProgram,
		DLSrcFormat.fileGroup,
	].includes(fileInfo.srcFormat)
	//
	// ===============================
	//
	return [
		{
			label: "File Download (d)",
			value: "ctx-download-file",
			available: permission.export,
			clickEvent: handleFileDownload,
			disabled: itemsCount > 1 || itemsCount === 0,
			icon: (
				<DLIcon
					name={DLIconName.download}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Open with Online Editor (w)",
			value: "ctx-file-open",
			available: permission.read,
			disabled: itemsCount > 1 || itemsCount === 0,
			isLink: fileInfo.srcFormat === DLSrcFormat.finalAnalysis,
			linkTo: finalAnalysisLink,
			clickEvent: handleFileOpen,
			icon: <IconPlaceholder />,
		},
		// divider
		{
			label: i18n.twReplace || "Replace (s)",
			value: "ctx-replace-file",
			available: permission.update && !isLocked && !isReplaceAvailable,
			disabled: itemsCount > 1 || itemsCount === 0 || fileInfo.isOpen,
			clickEvent: () =>
				setFileTreeDialogOpen(FileScreenDialog.replaceFile, true),
			icon: (
				<DLIcon
					name={DLIconName.replace}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Edit File Info (e)",
			value: "ctx-edit-file",
			available: permission.update && !isLocked,
			disabled: itemsCount > 1 || itemsCount === 0 || fileInfo.isOpen,
			clickEvent: () =>
				setFileTreeDialogOpen(FileScreenDialog.fileEdit, true),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		// divider
		{
			label:
				itemsCount > 1
					? `${i18n.twPrepare || "Prepare"} (${itemsCount})`
					: `${i18n.twPrepare || "Prepare"} (p)`,
			value: "ctx-file-prepare",
			available: permission.signOff && !isLocked,
			disabled:
				!checkAvailableSignOffs.prepare ||
				!ableToPrepare ||
				isSignOffLocked,
			clickEvent: () => {
				// NOTE: Is required to allow multiple sign off by context menu..??
				handleSignOff(OldSignOffType.prepare)
			},
			icon: <PrepareIcon />,
		},
		{
			label:
				itemsCount > 1
					? `${i18n.twReview || "Review"} (${itemsCount})`
					: `${i18n.twReview || "Review"} (r)`,
			value: "ctx-file-review",
			available: permission.signOff && !isLocked,
			disabled:
				!checkAvailableSignOffs.review ||
				!ableToReview ||
				isSignOffLocked,
			clickEvent: () => {
				handleSignOff(OldSignOffType.review)
			},
			icon: <ReviewIcon />,
		},

		{
			label:
				itemsCount > 1
					? `${i18n.twUnPrepare || "Unprepare"} (${itemsCount})`
					: i18n.twUnPrepare || "Unprepare",
			value: "ctx-file-unprepare",
			available: permission.signOff && !isLocked,
			disabled:
				!checkAvailableSignOffs.unPrepare ||
				!ableToPrepare ||
				isSignOffLocked,
			clickEvent: () => {
				handleUnSignOff(OldSignOffType.prepare)
			},
			icon: <IconPlaceholder />,
		},
		{
			label:
				itemsCount > 1
					? `${i18n.twUnReview || "Unreview"} (${itemsCount})`
					: i18n.twUnReview || "Unreview",
			value: "ctx-file-unreview",
			available: permission.signOff && !isLocked,
			disabled:
				!checkAvailableSignOffs.unReview ||
				!ableToReview ||
				isSignOffLocked,
			clickEvent: () => {
				handleUnSignOff(OldSignOffType.review)
			},
			icon: <IconPlaceholder />,
		},
		// divider
		{
			label: `Sign Off ${fileInfo.signOffLock ? "Unlock" : "Lock"} (l)`,
			value: "ctx-file-signoff-lock",
			available: permission.read && !isLocked,
			disabled: itemsCount > 1 || itemsCount === 0,
			clickEvent: () => {
				setFileTreeDialogOpen(FileScreenDialog.signOffLockDialog, true)
			},
			icon: <IconPlaceholder />,
		},
		// divider
		{
			label: "Role Assign / Unassign (a)",
			value: "ctx-assign-role",
			available: permission.update && !isLocked,
			disabled: itemsCount > 1 || itemsCount === 0,
			clickEvent: handleAssignRole,
			icon: <IconPlaceholder />,
			// subMenus: rolesArray(),
		},
		// divider
		{
			label: "Add Review Comment",
			value: "ctx-add-comment",
			available: commentPermission.create && !isLocked,
			disabled: itemsCount > 1 || itemsCount === 0,
			clickEvent: () => handleOpenCommentAddDialog(DLCommentType.normal),
			icon: (
				<DLIcon
					name={DLIconName.addComment}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Add QC Comment",
			value: "ctx-add-qc-comment",
			available: commentPermission.create && !isLocked,
			disabled: itemsCount > 1 || itemsCount === 0 || projRole !== "QC",
			clickEvent: () => handleOpenCommentAddDialog(DLCommentType.qc),
			icon: (
				<DLIcon
					name={DLIconName.addComment}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: i18n.twViewComment || "View Comments",
			value: "ctx-view-comments",
			available: commentPermission.read && !isLocked,
			disabled: itemsCount > 1 || itemsCount === 0,
			clickEvent: handleOpenCommentListOfFile,
			icon: <IconPlaceholder />,
		},
		// divider
		// NOTE: consider more about diverse formats and project status for below menu
		// {
		// 	label: "Open as readonly",
		// 	value: "ctx-file-open-as-readonly",
		// 	available:
		// 		permission.read &&
		// 		(fileInfo.srcFormat === DLSrcFormat.excel ||
		// 			fileInfo.srcFormat === DLSrcFormat.word),
		// 	disabled: itemsCount > 1 || itemsCount === 0,
		// 	isLink: fileInfo.srcFormat === DLSrcFormat.finalAnalysis,
		// 	linkTo: finalAnalysisLink,
		// 	clickEvent: handleFileOpen,
		// 	icon: <IconPlaceholder />,
		// },
		{
			label: "Go To File Location",
			value: "ctx-go-to-file-location",
			available: partialStore.viewType !== FileMenuViewType.tree,
			disabled: itemsCount > 1 || itemsCount === 0,
			clickEvent: handleFetchFileLocation,
			icon: <IconPlaceholder />,
		},
		{
			label: i18n.twSetCheckout || "Set Checkout", // NOTE: 'Check out' means 'open' the file
			value: "ctx-file-checkout",
			available: permission.update && openedByName === null && !isLocked,
			tooltip:
				openedByName && openedByName !== userName
					? `${openedByName} checkedout this file already`
					: undefined,
			disabled:
				itemsCount > 1 ||
				itemsCount === 0 ||
				openedByName !== null ||
				openedByName === userName,
			clickEvent: handleCheckoutWithoutOpen,
			icon: <FileCheckoutIcon />,
		},
		{
			label: i18n.twSetCheckin || "Set Checkin", // NOTE: 'Check in' means 'close' the file
			value: "ctx-file-checkin",
			available:
				permission.update && openedByName === userName && !isLocked,
			tooltip:
				openedByName !== userName
					? "Cannot checkin the file which is used by other users"
					: undefined,
			disabled:
				itemsCount > 1 ||
				itemsCount === 0 ||
				!fileInfo?.isOpen ||
				openedByName === null ||
				openedByName !== userName,
			clickEvent: handleCheckinWithoutOpen,
			icon: <IconPlaceholder />,
			// icon: <CloseIcon />,
		},
		// WARNING: WATING FOR API UPDATE
		// {
		// 	label: i18n.twCheckoutToLocal || "Checkout to Local", // NOTE: set 'Check out' and download the file
		// 	value: "ctx-file-checkout-to-local",
		// 	available: permission.update && itemsCount === 1,
		// 	tooltip:
		// 		openedByName !== userName
		// 			? `${openedByName} checkedout this file already`
		// 			: undefined,
		// 	disabled:
		// 		itemsCount > 1 ||
		// 		itemsCount === 0 ||
		// 		openedByName !== null ||
		// 		openedByName === userName,
		// 	clickEvent: handleCheckoutToLocal,
		// 	icon: <FileCheckoutToLocalIcon />,
		// },
		// {
		// 	label: i18n.twCheckinFromLocal || "Checkin from Local", // NOTE: open "Replace" dialog
		// 	value: "ctx-file-checkin-from-local",
		// 	available: permission.update && itemsCount === 1,
		// 	tooltip:
		// 		openedByName !== userName
		// 			? "Cannot checkin the file which is used by other users"
		// 			: undefined,
		// 	disabled:
		// 		itemsCount > 1 ||
		// 		itemsCount === 0 ||
		// 		!fileInfo?.isOpen ||
		// 		openedByName !== null ||
		// 		openedByName !== userName,
		// 	clickEvent: handleCheckinFromLocal,
		// 	icon: <FileCheckinFromLocalIcon />,
		// },
		{
			label: i18n.twRequestCheckin || "Request Checkin",
			value: "ctx-file-request-checkin",
			available:
				openedByName !== userName &&
				fileInfo.isOpen &&
				permission.update &&
				!isLocked,
			disabled:
				itemsCount > 1 ||
				itemsCount === 0 ||
				!fileInfo.isOpen ||
				openedByName === userName,
			clickEvent: handleRequestCheckin,
			icon: <RequestIcon />,
		},
		{
			label: i18n.twDiscardCheckout || "Discard Checkout",
			value: "ctx-file-discard-checkout",
			available:
				openedByName !== userName &&
				fileInfo.isOpen &&
				permission.discardCheckout &&
				!isLocked,
			disabled:
				itemsCount > 1 ||
				itemsCount === 0 ||
				!fileInfo.isOpen ||
				openedByName === userName,
			clickEvent: handleDiscardCheckout,
			icon: <DLIcon name={DLIconName.forceClose} />,
		},
		// divider
		{
			label: "Info - Sign Off History",
			value: "ctx-file-information",
			available: permission.read,
			disabled: itemsCount > 1 || itemsCount === 0,
			clickEvent: () =>
				setFileTreeDialogOpen(FileScreenDialog.fileInfo, true),
			icon: <InfoIcon />,
		},
		{
			label: "Info - Sign Off Lock History",
			value: "ctx-file-signoff-lock-history",
			available: permission.read,
			disabled: itemsCount > 1 || itemsCount === 0,
			clickEvent: () => {
				setFileTreeDialogOpen(
					FileScreenDialog.signOffLockUnlockHistoryDialog,
					true
				)
			},
			icon: <IconPlaceholder />,
		},
		{
			label: "Info - File History",
			value: "ctx-file-history",
			available: permission.read,
			disabled: itemsCount > 1 || itemsCount === 0,
			clickEvent: () => {
				setFileTreeDialogOpen(FileScreenDialog.FileHistoryDialog, true)
			},
			icon: <IconPlaceholder />,
		},
		// divider
		// {
		// 	label: i18n.twSignoffLog || "Sign Off Log",
		// 	value: "ctx-file-sign-off-logs",
		// 	disabled: itemsCount > 1 || itemsCount === 0,
		// 	clickEvent: handleOpenSignOffDetailDialog,
		// 	icon: <HistoryIcon />,
		// },
		// {
		// 	label: "Add Review Comment",
		// 	value: "ctx-add-review-comment",
		// },
		// {
		// 	label: "Add QC Comment",
		// 	value: "ctx-add-qc-comment",
		// },
		// NOTE: Try to use one comment (because normal users cannot use QC comment already)
		// {
		// 	label: "Unassign from Role",
		// 	value: "ctx-unassign-role",
		// },
		{
			label:
				itemsCount > 1
					? `${i18n.twMoveFiles || "Move Files"} (${itemsCount})`
					: i18n.twMoveFile || "Move File",
			value: "ctx-move-file",
			available: permission.update && !isLocked,
			disabled: itemsCount === 0 || structureLock,
			clickEvent: () =>
				setFileTreeDialogOpen(FileScreenDialog.moveFileOrFolder, true),
			icon: <MoveIcon />,
		},
		{
			label: itemsCount > 1 ? `Delete (${itemsCount})` : "Delete (x)",
			value: "ctx-delete-file",
			available: permission.delete && !isLocked,
			disabled: itemsCount > 1 || itemsCount === 0 || fileInfo.isOpen,
			clickEvent: handleFileDelete,
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]
}

export default FileContextMenuOptions
