import {
	getTreeFromFlatData,
	compareValues,
} from "../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { DnTFormatProps } from "../../../common-models/types/common-props"
import {
	formattedDateWithTz2,
	getTzDateAndTime2,
} from "../../../library/converters/date-utc-converter"
import { DLFileProps, RoleModelProps } from "../data-models/file.data-model"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { SimpleFileTreeProps } from "../data-models/file.data-props"
import { ConsoleLog } from "../../../components/basic-elements"
import { CommentStatus } from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import { formatFileSize } from "@datalobby/common/lib"
import { FileMenuViewType } from "../data-models/dl-file-control-model"
import {
	getCommentStatus,
	getAttentionStatus,
	getAssignedRolesForAFile,
} from "../../../library/file-utils/file-utils"
import { getSnapshot } from "mobx-state-tree"

const FlatListViews = (self: any) => ({
	formattedFileById(fileId: string, dntFormat: DnTFormatProps) {
		const file = self.flatList.find((file: any) => file.id === fileId)
		let formattedDate: string = ""
		let formattedFile: any

		if (file) {
			if (file.lastModifiedAt !== "") {
				const modifiedAt = new Date(file.lastModifiedAt)
				formattedDate = getTzDateAndTime2({
					date: modifiedAt,
					dntFormat,
				})
			}

			formattedFile = { ...file, lastModifiedAt: formattedDate }
			return formattedFile
		}
	},
	formattedFileInfoById(fileId: string, dntFormat: DnTFormatProps) {
		const file = self.flatList.find((file: any) => file.id === fileId)

		if (file && !file.isParent) {
			const lastModifiedAt = file.lastModifiedAt
			const tzLastModifiedAt = lastModifiedAt
				? getTzDateAndTime2({
						date: lastModifiedAt,
						dntFormat,
				  })
				: "-"
			const parentName = self.getItemById(file.parentId).title
			const assignedRoles = file.assignedRoles
			let assignedRolesList: string[] = []
			assignedRoles.map((role: RoleModelProps) =>
				assignedRolesList.push(role.shortName)
			)
			return {
				Folder: parentName,
				"Alias ID (Ref. Num)": file.aliasId,
				"File Name": file.title,
				Menu:
					file.menuId === DLProjSubMenus.wp
						? "Workpaper Files"
						: file.menuId === DLProjSubMenus.pf
						? "Permanent Files"
						: "?",
				"Object Format": file.objectFormat,
				"Source Format": file.srcFormat,
				Size: file.size,
				Extension: file.extension,
				"Checkout Status": file.isOpen ? "Checked out (Opened)" : "-",
				"Checkedout by": file.openedByName ? file.openedByName : "-",
				"Upload Status": file.uploadStatus ? file.uploadStatus : "-",
				"Assigned Roles":
					assignedRolesList.length > 0
						? assignedRolesList.join(", ")
						: "-",
				"Last Modified by": file.lastModifiedBy.userName,
				"Last Modified at": tzLastModifiedAt,
			}
		} else {
			ConsoleLog("Cannot find matched file: " + fileId)
		}
	},
	viewFileInfo(dntFormat: DnTFormatProps) {
		const file = self.fileInfo
		console.log("file", getSnapshot(file))
		const { lastModifiedBy, lastModifiedDate, createdDate } = file
		const tzLastModifiedDate = lastModifiedDate
			? getTzDateAndTime2({
					date: lastModifiedDate,
					dntFormat,
			  })
			: "-"
		const tzCreatedDate = createdDate
			? getTzDateAndTime2({
					date: createdDate,
					dntFormat,
			  })
			: "-"
		return {
			Folder: file.parentName,
			"Ref. Num": file.alias,
			"File Name": file.title,
			Size: file.size,
			Extension: file.extension || file.srcFormat,
			"Attention Status": getAttentionStatus(file.attentionStatus),
			"Checkout Status": file.isCheckedOut ? "Checked out (Opened)" : "-",
			"Checkedout By": file.checkedOutBy.name
				? `${file.checkedOutBy.name} [${file.checkedOutBy.aliasId}]`
				: "-",
			"Assigned Roles": getAssignedRolesForAFile(file.assignedRoles),
			"Last Modified By": lastModifiedBy.name
				? `${lastModifiedBy.name} [${lastModifiedBy.aliasId}]`
				: "-",
			"Last Modified At": tzLastModifiedDate,
			"Created By":
				`${file.createdBy.name} [${file.createdBy.aliasId}]` || "-",
			"Created At": tzCreatedDate,
			"Review Comments": getCommentStatus(file.reviewComments),
			"QC Comments": getCommentStatus(file.qcComments),
			Rationale: file.hasRationale ? "Yes" : "No",
			// Menu:
			// 	file.menuId === DLProjSubMenus.wp
			// 		? "Workpaper Files"
			// 		: file.menuId === DLProjSubMenus.pf
			// 		? "Permanent Files"
			// 		: "?",
			// "Object Format": file.objectFormat,
			// "Source Format": file.srcFormat,
			//"Upload Status": file.uploadStatus ? file.uploadStatus : "-",
		}
	},
	getItemById(itemId: string) {
		const target = self.flatList.find((item: any) => item.id === itemId)
		return target
	},
	getSrcIdOfFile(fileId: string) {
		const target = self.flatList.find((item: any) => item.id === fileId)
		return target.srcId
	},
	fileShortInfo(fileId: string, from?: string) {
		const viewType = self.viewType
		let file: any = undefined

		file =
			self.viewType === FileMenuViewType.tree ||
			self.viewType === FileMenuViewType.fileTable
				? self.flatList.find((file: any) => file.id === fileId)
				: self.searchResults.find((file: any) => file.id === fileId)

		if (file) {
			return { id: file.id, aliasId: file.aliasId, title: file.title }
		} else {
			console.log(
				viewType,
				"(fileShortInfo) No matched file. fileId is :",
				fileId,
				"| from:",
				from
			)
			return { id: fileId, aliasId: "(unknown)", title: "(unknown)" }
		}
	},
	flatListAsTree(dntFormat: DnTFormatProps) {
		const formatted = self.flatList.map((item: DLFileProps) => {
			return {
				...item,
				lastModifiedAt:
					item.lastModifiedAt === ""
						? "-"
						: getTzDateAndTime2({
								date: item.lastModifiedAt,
								dntFormat,
						  }),
			}
		})
		// ConsoleLog(
		// 	" !!!! (Performance check) flatListAsTree - getTreeFromFlatData"
		// )
		const tree = getTreeFromFlatData(formatted, "title")

		return tree
	},
	get fileCount() {
		const files = self.flatList.filter((item: any) => !item.isParent)
		if (files) {
			return files.length
		} else {
			return 0
		}
	},
	formattedFileOnly(
		dntFormat: DnTFormatProps,
		forExport?: boolean,
		adminMode?: boolean
	) {
		// WARNING: Check the viewType. This method is using for both of file table and search results
		// - when the viewType is 'search', return the search results,
		// - when the viewType is fileTable or tree, return the file list
		const files =
			self.viewType === FileMenuViewType.search ||
			self.viewType === FileMenuViewType.qcView
				? self.searchResults
				: self.flatList.filter((item: any) => !item.isParent)

		let list: any[] = []

		console.log(files, "files1")
		files.map((item: any) => {
			let formattedDate = ""
			let stringLastModifiedAt = "-"

			if (item.lastModifiedAt !== "") {
				const convertedDate = formattedDateWithTz2({
					date: item.lastModifiedAt,
					timeZone: dntFormat.timeZone,
					timeFormat: dntFormat.timeFormat,
					dateFormat: dntFormat.dateFormat,
				})
				formattedDate = convertedDate.date + " " + convertedDate.time
			} else {
				formattedDate = "-"
			}

			if (item.lastModifiedBy.userName !== "") {
				stringLastModifiedAt =
					"(" +
					item.lastModifiedBy.roleShortName +
					") " +
					item.lastModifiedBy.userName
			}

			// ------ MAIN PART -----
			if (!forExport) {
				// return all columns for table view
				const viewType = self.viewType
				list.push({
					parentFolder:
						viewType === FileMenuViewType.search
							? { id: "", aliasId: "", title: "" }
							: self.fileShortInfo(
									item.parentId,
									"formattedFileOnly-parentId"
							  ).title,
					referenceNumber: item.aliasId,
					...item,
					lastModifiedBy: stringLastModifiedAt,
					lastModifiedAt: formattedDate,
				})
			} else {
				if (adminMode) {
					// return all columns
					list.push({
						parentFolder: self.fileShortInfo(
							item.parentId,
							"formattedFileOnly-adminMode export"
						).title,
						referenceNumber: item.aliasId,
						...item,
						lastModifiedBy: stringLastModifiedAt,
						lastModifiedAt: formattedDate,
					})
				} else {
					// return specific columns
					const size = item.size.slice(0, -2) * 1000 // this size is KB, that's why need to multiple 1000
					const newSize = formatFileSize(size, 2)
					list.push({
						parentFolder: self.fileShortInfo(
							item.parentId,
							"formattedFileOnly-normalMode export"
						).title,
						referenceNumber: item.aliasId,
						title: item.title,
						size: newSize,
						extension: item.extension,
						prepareList: item.prepareList,
						reviewList: item.reviewList,
						epReviewList: item.epReviewList,
						cpReviewList: item.cpReviewList,
						qcReviewList: item.qcReviewList,
						status: item.status,
						qcComment:
							item.qcCommentStatus === CommentStatus.notExist
								? "-"
								: item.qcCommentStatus,
						reviewComment:
							item.reviewCommentStatus === CommentStatus.notExist
								? "-"
								: item.reviewCommentStatus,
						lastModifiedBy: stringLastModifiedAt,
						lastModifiedAt: formattedDate,
					})
				}
			}
		})

		list.sort(compareValues("referenceNumber")).sort(
			compareValues("parentFolder")
		)
		if (list.length === files.length) {
			return list
		} else {
			return []
		}
	},
	forFileTableExport(dntFormat: DnTFormatProps) {
		const files = self.flatList.filter((item: any) => !item.isParent)
		let list: any[] = []

		const getSignOffDetails = (list: any) => {
			const userDetails = list
				.slice()
				.sort(
					(a: any, b: any) =>
						new Date(b.signedAt).getTime() -
						new Date(a.signedAt).getTime()
				)[0]
			return (
				userDetails.userName +
				" " +
				getTzDateAndTime2({ date: userDetails.signedAt, dntFormat })
			)
		}
		files.map((item: any) => {
			let formattedDate
			if (item.lastModifiedAt !== "") {
				const date = formattedDateWithTz2({
					date: item.lastModifiedAt,
					timeZone: dntFormat.timeZone,
					dateFormat: dntFormat.dateFormat,
				})
				formattedDate = date.date + " " + date.time
			} else {
				formattedDate = "-"
			}

			const assignedRoles = item.assignedRoles.map(
				(role: any) => role.shortName
			)
			const review = item.reviewList.length
				? getSignOffDetails(item.reviewList)
				: ""
			const prepare = item.prepareList.length
				? getSignOffDetails(item.prepareList)
				: ""
			const ep = item.epReviewList.length
				? getSignOffDetails(item.epReviewList)
				: ""
			const qc = item.qcReviewList.length
				? getSignOffDetails(item.qcReviewList)
				: ""
			const cp = item.cpReviewList.length
				? getSignOffDetails(item.cpReviewList)
				: ""
			// console.log(ep,"reviewList")
			list.push({
				aliasId: item.aliasId,
				parentFolderName: self.fileShortInfo(
					item.parentId,
					"forFileTableExport"
				).title,
				title: item.title,
				size: item.size,
				extension: item.extension,
				assignedRoles: assignedRoles.join(", "),
				status: item.status,
				qcCommentStatus: item.qcCommentStatus,
				reviewCommentStatus: item.reviewCommentStatus,
				isPrepared: item.isPrepared,
				isReviewed: item.isReviewed,
				review,
				prepare,
				ep,
				qc,
				cp,
				lastModifiedBy: item.lastModifiedBy.userName,
				lastModifiedAt: formattedDate,
			})
			// console.log(list)
			if (list.length === files.length) {
				return list
			} else {
				return []
			}
		})
	},
	isDuplicatedFolderName(folderName: string) {
		if (
			self.flatList
				.filter((item: any) => item.isParent)
				.findIndex(
					(item: any) =>
						item.title.toLowerCase() === folderName.toLowerCase()
				) === -1
		) {
			// This new folder name not exists on the list
			return false
		} else {
			// This new folder name already exist on the list
			return true
		}
	},
	isDuplicatedAliasId(aliasId: string) {
		const targetIndex = self.flatList
			.filter((item: any) => !item.isParent)
			.findIndex(
				(item: any) =>
					item.aliasId.toLowerCase() === aliasId.toLowerCase()
			)
		if (targetIndex === -1) {
			return false
		} else {
			return true
		}
	},
	isDuplicatedName(name: string) {
		const targetIndex = self.flatList.findIndex(
			(item: any) => item.title.toLowerCase() === name.toLowerCase()
		)
		if (targetIndex === -1) {
			return false
		} else {
			return true
		}
	},
	viewSimpleFileTree(type: "folder-only" | "all") {
		let targets: SimpleFileTreeProps[] = []
		function makeSimpleItem(item: DLFileProps) {
			return {
				id: item.id,
				isParent: item.isParent,
				parentId: item.parentId,
				title: item.title,
				index: item.index,
				aliasId: item.aliasId,
				expanded: item.expanded,
			}
		}
		if (type === "folder-only") {
			const folders = self.flatList.filter(
				(item: DLFileProps) => item.isParent
			)
			folders.map((folder: DLFileProps) => {
				targets.push(makeSimpleItem(folder))
			})
		} else {
			self.flatList.map((item: DLFileProps) => {
				targets.push(makeSimpleItem(item))
			})
		}
		ConsoleLog(
			" #### (Performance check) viewSimpleFileTree - getTreeFromFlatData"
		)
		const treeData = getTreeFromFlatData(targets, "index")
		return treeData
	},
	get folderList() {
		const folders = self.flatList.filter((item: any) => item.isParent)
		return folders
	},
	get folderNotExist() {
		if (self.flatList.find((item: any) => item.parentId === null)) {
			return false
		} else {
			return true
		}
	},
	getSignOffLockHistories(dntFormat: DnTFormatProps) {
		const fileHistory = self.fileHistory
		function formatDate(date: string | null) {
			if (!date) {
				return ""
			}
			return getTzDateAndTime2({
				date,
				dntFormat,
			})
		}

		let formattedList: any[] = []

		fileHistory.map((file: any) => {
			const formattedCreatedDate = formatDate(file.createdDate)

			formattedList.push({
				attentionStatus: file.attentionStatus,
				refName: file.refName,
				fileName: file.fileName,
				activityName: file.activityName,
				status: file.signOffLock ? "Locked" : "Unlocked",
				createdDate: formattedCreatedDate,
				createdBy: `(${file.createdBy.roleAliasName}) (${file.createdBy.aliasId}) ${file.createdBy.name}`,
			})
		})

		return formattedList
	},
})

export default FlatListViews
