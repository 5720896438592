import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLButton, DLDialog } from "../../../../../components/basic-elements"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import styled from "styled-components"
import { getProjIdFromUrl } from "../../../../../library/api-requests/request-get-others"
import { useAsync } from "../../../../../library/useAsync"
import { getFileSize } from "../../workpaper-files/store/wp.apis"
import {
	IdType,
	idToNumber,
} from "../../../../../library/converters/id-converter"

const ExportFolderDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		const projStore = useProjStore()
		const projId = getProjIdFromUrl()

		const dialogName = FileScreenDialog.exportFolderDialog
		const downloadType = partialStore.downloadType

		const objectId = partialStore.selectedItems

		let folderInfo
		if (downloadType === "Folder") {
			folderInfo = partialStore.getItemInfo(objectId[0].id)
		}

		const [{ data, isLoading }, getSize] = useAsync(getFileSize)

		const {
			clientName,
			projAliasId,
			projTitle,
			periodName,
			financialYear,
			engPartner,
			clientAliasId,
		} = projStore.projInfo.projInfo

		useEffect(() => {
			let folderId
			if (downloadType === "Folder") {
				folderId = idToNumber(objectId[0].id, IdType.folder)
			}
			getSize({ menuId: partialStore.storeName, folderId })
		}, [])

		const open = partialStore.fileTreeDialogOpenStatus[dialogName]

		const projectDetails = {
			periodName,
			financialYear,
			clientName,
			title: projTitle,
			aliasId: projAliasId,
			epUsers: engPartner,
		}

		const size: any = data?.FileSize

		const projectSize =
			size && size !== null && size !== ""
				? parseFloat(size?.match(/[\d.]+/)[0])
				: 0
		const overSize = projectSize * 1024 >= 200 * 1024

		const handleExport = () => {
			if (downloadType === "Folder") {
				partialStore.downloadFileFolder({
					folderId: objectId[0].id,
					projId,
				})
			} else {
				partialStore.downloadAllAsZip({
					projId,
					fileNameZip: `${clientAliasId} - ${projTitle}(${projAliasId}).zip`,
				})
			}
		}

		return (
			<DLDialog
				eleTestId="export-folder-dialog"
				isOpen={open}
				setIsOpen={() => partialStore.setFileTreeDialogOpen(dialogName)}
				showOpenBtn={false}
				showCloseBtn={true}
				dialogTitle={`Export Workpapers`}
				dialogContents={
					<StyledExport>
						<div>
							<b>File Size:</b> {data?.FileSize}
						</div>
						<div className="mt-12">
							<span className="warning">WARNING:</span> If the
							file size is above 200 MB, it cannot be exported
							through this process. Please use the individual file
							download option
						</div>

						{downloadType === "Folder" && (
							<div className="mt-12">
								<h4 style={{ marginBottom: 10 }}>
									Folder Info
								</h4>
								<InfoRow
									label="Folder Name"
									value={folderInfo.title}
									testId="folder-name"
								/>
							</div>
						)}

						<div className="mt-12">
							<ProjectInfoContainer
								projectDetails={projectDetails}
							/>
						</div>
					</StyledExport>
				}
				openBtn={<div />}
				cancelBtnText="Cancel"
				actionReady={true}
				actionBtn={
					<DLButton
						eleTestId="add-new-row"
						color="primary"
						variant="contained"
						disabled={overSize}
						clickHandler={handleExport}
					>
						Export
					</DLButton>
				}
				// handleAction={downloadPDF}
				maxWidth="sm"
				showSpinner={isLoading}
			/>
		)
	}
)

export default ExportFolderDialog

const ProjectInfoContainer = ({ projectDetails }: { projectDetails: any }) => {
	return (
		<div>
			<h4 style={{ marginBottom: 15 }}>Project Info</h4>
			<InfoRow
				label={"Period Type"}
				value={projectDetails.periodName}
				testId="periodType"
			/>

			<InfoRow
				label={"Year"}
				value={projectDetails.financialYear}
				testId="financialYear"
			/>

			<InfoRow
				label={"Client"}
				value={projectDetails.clientName}
				testId="client"
			/>

			<InfoRow
				label={"Project Name"}
				value={projectDetails.title}
				testId="proj-title"
			/>
			<InfoRow
				label={"Project ID"}
				value={projectDetails.aliasId}
				testId="proj-alias-id"
			/>
			<InfoRow
				label={"E.P."}
				value={projectDetails.epUsers}
				testId="ep"
			/>
		</div>
	)
}

const InfoRow = ({
	label,
	value,
	testId,
}: {
	label: string
	value: any
	testId: string
}) => {
	return (
		<div className="info-row">
			<div className="label-area">{label} :</div>
			<div className="value-area" data-testid={`projDetails-${testId}`}>
				{value}
			</div>
		</div>
	)
}

const StyledExport = styled.div`
	.mt-2 {
		margin-top: 2px;
	}
	.mt-12 {
		margin-top: 12px;
	}
	.warning {
		color: orange;
		font-weight: bold;
	}
	.info-row {
		display: flex;
		padding: 0.3rem 0 0.3rem 1rem;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		.label-area {
			min-width: 8rem;
		}
		.value-area {
		}
	}
	.partition {
		height: 1.8rem;
	}
`
