import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
// import ProjInfo from "./ProjInfo"
import ProjInfo from "./ProjInfo-2022"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import ProjNoPermissionOnMenu from "../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import {
	ActionStatus,
	DLSector,
} from "../../../common-models/enumerations/common-enums"
import ProjInfoProjUsers from "./sub-components/ProjInfoProjUsers"
import { PageContainer } from "../../../components/app-frame-elements"
import styled from "styled-components"
import { DLButton } from "../../../components/basic-elements"

export default observer(function ProjInfoController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.proj_info
	const actionName = "getProjInfo"
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	// let { id } = useParams<{ id: string }>()
	// console.log("~~~~ ProjInfoCtrl - projNumId:", id)

	const needRefresh = projStore.projInfo.needRefresh

	useEffect(() => {
		if (menuAccess) {
			// NOTE: for the first time rendering
			// because 'needRefresh' is false when this page is rendered
			// (get project information is called on checkin process)
			// have to place hese two actions separately
			if (
				projStore.projUsers.getActionStatus("getProjUsers") !==
				ActionStatus.success
			) {
				projStore.projUsers.getProjUsers()
				projStore.roles.getRoleSet(DLSector.proj)
				// NOTE: this user information is stored on the setup > project user store
				// and used for multiple screens which requires user information but has not enough data from server
			}
		}
	}, [menuAccess])

	useEffect(() => {
		const projId = projStore.checkin.projId
		console.log(projId)
		if (menuAccess && needRefresh) {
			projStore.projInfo.getProjInfo(projId)

			// call again the project users also
			projStore.projUsers.getProjUsers()
			projStore.roles.getRoleSet(DLSector.proj)
		}
	}, [menuAccess, needRefresh])

	// project information screen i18n data are covered by common sheet
	const i18n = store.i18n.globalI18n("ProjInfoController")
	const contentsWidth = store.ui.contentsAreaWidth
	const displayAsRow = contentsWidth > 1000
	const dntFormat = store.global.getDntFormat

	return (
		<>
			{menuAccess ? (
				<PageContainer
					pageTools={<div style={{ width: "100%" }}></div>}
					hasToolbar={false}
					fullWidth
				>
					<div className={displayAsRow ? "FR" : "FC"}>
						<ProjInfo
							partialStore={projStore.projInfo}
							fetchingStatus={projStore.projInfo.getActionStatus(
								actionName
							)}
							permission={permission}
							dntFormat={dntFormat}
							i18n={i18n}
						/>
						{!displayAsRow && <div style={{ height: "3rem" }} />}
						<ProjInfoProjUsers i18n={i18n} />
					</div>
					{projStore.projInfo.projInfo.hasExportAccess && (
						<StyledBottomArea screenWidth={contentsWidth}>
							<DLButton
								variant="contained"
								eleTestId="export-arch-zip-btn"
								color="primary"
								size="thin"
								align="left"
								clickHandler={() =>
									projStore.projInfo.exportArchivedZipFile(
										projStore.projInfo.projInfo.projectId
									)
								}
							>
								Export Archive Zip
							</DLButton>
						</StyledBottomArea>
					)}
				</PageContainer>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

type StyledProps = {
	screenWidth: any
}

const StyledBottomArea = styled.div<StyledProps>`
	position: absolute;
	background-color: ${(props) => props.theme.secondaryDeep};
	border-top: 1px solid white;
	width: ${(props) => props.screenWidth}px;
	padding: 1rem 2rem;
	bottom: 0;
	> div {
		margin-right: 1rem;
	}
`
