import {
	ActionStatus,
	MessageColorType,
	DLCommentType,
	PriorityType,
} from "../../../../../../common-models/enumerations/common-enums"
import { HandleResponseProps } from "../../../../../../common-models/common-view-model-actions"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import reOrganizeComment from "./util-reorganize-comment"
import { getProjIdFromUrl } from "../../../../../../library/api-requests/request-get-others"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

export type AddCommentProps = {
	priority: PriorityType
	type: DLCommentType
	assigneeIds: string[] | undefined
	contents: string
	//
	aliasId: string // cannot use objAliasId here because will get getItemInfo
	fileId: string
}

export type CommentActionProps = {
	comment: AddCommentProps
	menuId: DLProjSubMenus
	//
	userName: string // to reflect on screen (not for API)
	fileName: string // to reflect on screen (not for API)
	assigneeNames: string[]
	//
	postWorkForSuccess: () => void
	handleResponse: (props: HandleResponseProps) => void // for snackbar update on wp, pf
}

const AddComment = (self: any) => ({
	addComment({
		comment,
		menuId,
		userName,
		fileName,
		assigneeNames,
		postWorkForSuccess,
		handleResponse,
	}: CommentActionProps) {
		// 0.
		const actionName = "addComment"
		// 1.
		// 2.
		handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		const props = {
			projId: getProjIdFromUrl(),
			// is this not required on org?
			// TODO: @Noah: Isn't it better to send the project ID as props...?
			comment: comment.contents,
			userId: localStorage.getItem("orgUserId"),
			priority: comment.priority,
			type: comment.type,
			assignedUsers: comment.assigneeIds,
			objAliasId: comment.aliasId,
			fileId: comment.fileId,
			menuId,
		}
		self.createComment(props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName, "--response", response)
					// NOTE: WARNING: Is this come from comment store? or wp, pf?
					// self.setOpenAddDialog(false)
					// NOTE: (TBD) Because this action is shared between comment screen, wp, pf... let's get post-work

					const fetchedComment = response.data
					const newFetchedComment = {
						...fetchedComment,
						// below values are undefined or null in the API response
						WPREF: comment.aliasId,
						WPDesc: fileName,
						CreatedUser: userName,
						ReviewStatus: "Open", // API return string "Open" for opened comments
						AssignedUser: assigneeNames.join(","),
						// 'AssignedTo' return assigned user ID only. need more update to get userName from the project user store
						// when there are multiple assignees, response return like this "933,949,954"
					}

					const reOrganizedComment = reOrganizeComment({
						fetchedComment: newFetchedComment,
						menuId,
					})
					self.pushItem(reOrganizedComment)
					postWorkForSuccess()
					//
					// set success case response
					handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response (200 but fail)
					handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					// NOTE: TODO: handleResponse occurs issue by unmatched props
					// but also I don't want to get this handleViewModelError from the remote module...
					// Let's use self (comment) store action temporary and update more next time...
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddComment
