import { HandleResponseProps } from "../../../../../../common-models/common-view-model-actions"
import {
	ActionStatus,
	MessageColorType,
	PriorityType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { commentIdToNumber } from "./utils-for-comment"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

export type EditCommentProps = {
	id: string
	contents: string
	priority: PriorityType
	assigneeIds: string[] | undefined
}

const EditComment = (self: any) => ({
	editComment({
		comment,
		menuId,
		postWorkForSuccess,
		handleResponse,
	}: {
		comment: EditCommentProps
		menuId: DLProjSubMenus
		postWorkForSuccess: () => void
		handleResponse: (props: HandleResponseProps) => void
	}) {
		// 0.
		const actionName = "editComment"
		// 1.
		// 2.
		handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})

		let UserId: number[] = []
		comment.assigneeIds?.map((user) => {
			const numId = idToNumber(user, IdType.user)
			if (typeof numId === "number") {
				UserId.push(numId)
			} else {
				alert(`Assigned user ID is invalid. ${numId}`)
			}
		})

		// 3.

		let payload = {
			Comment: comment.contents,
			// ModifiedBy: localStorage.getItem("orgUserId"),
			Priority: comment.priority,
			ReviewCommentsId: commentIdToNumber(comment.id),
			UserId: UserId.join(","),
		}

		// ConsoleLog(payload)
		// return false
		self.updateComment(payload, menuId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					/**
					 * REFLECT
					 */
					self.updateCommentList(comment)
					postWorkForSuccess()
					// set success case response
					handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					// set fail case response (200 but fail)
					handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditComment
