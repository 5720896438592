import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import {
	DLIconButton,
	DLButton,
	DLSpinner,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { SignoffhistoryTableColumns1 } from "./SignOffHistoryTableColumns"
import { Icon } from "@mdi/react"
import {
	mdiUnfoldLessHorizontal,
	mdiArrowExpandVertical,
	mdiFileExcel,
	mdiFilePdf,
} from "@mdi/js"
import SignOffHistoryDetailMore from "./SignOffHistoryDetailMore"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { handleExport } from "../../../../../components/basic-elements/export/ExportXLSX"
import ExportDetailHistoryDialog from "./ExportDetailHistoryDialog"
import { periodOptions } from "../../../../../common-models/enumerations/org-related-enums"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"

export default observer(function SignOffHistoryDetail({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const partialStore = projStore.signOff

	let defaultExpanded: { [x: number]: {} } = {}
	const [expanded, setExpanded] = useState<{
		[x: number]: boolean | any
	}>({
		0: {},
	})

	const [needCollapseAll, setNeedCollapseAll] = useState(true)
	const [needExpandAll, setNeedExpandAll] = useState(false)

	// const handleExpand = (props: any) => {
	// 	setExpanded(props.newExpanded)
	// }

	const foldersCount = partialStore.foldersCount(partialStore.selectedTab)

	useEffect(() => {
		for (let i = 0; i < foldersCount; i++) {
			defaultExpanded[i] = {}
		}

		setExpanded(defaultExpanded)
	}, [foldersCount])

	useEffect(() => {
		for (let i = 0; i < foldersCount; i++) {
			if (expanded[i] === false) {
				setNeedExpandAll(true)
				break
			}
			setNeedExpandAll(false)
		}
		for (let i = 0; i < foldersCount; i++) {
			if (expanded[i] !== false) {
				setNeedCollapseAll(true)
				break
			}
			setNeedCollapseAll(false)
		}
	}, [expanded, foldersCount])

	const handleExpandAll = () => {
		let newExpanded: { [x: number]: boolean } = {}
		for (let i = 0; i < foldersCount; i++) {
			newExpanded[i] = true
		}
		setExpanded(newExpanded)
	}
	const handleCollapseAll = () => {
		let newExpanded: { [x: number]: boolean } = {}
		for (let i = 0; i < foldersCount; i++) {
			newExpanded[i] = false
		}
		setExpanded(newExpanded)
	}

	const handleSignOffSummaryExport = () => {
		let proceed = window.confirm(
			"Do you really want to export sign off summary?"
		)
		if (!proceed) return
		const data = partialStore.exportLastSignOff(
			partialStore.selectedTab,
			store.global.getDntFormat
		)
		const { periodName, financialYear, clientName } =
			projStore.projInfo.projInfo
		const periodAbbrName = periodOptions.find(
			(item: any) =>
				item.label.replace(/ /g, "").toLowerCase() ===
				periodName.replace(/ /g, "").toLowerCase()
		)?.abbr
		const fileType =
			partialStore.selectedTab === DLProjSubMenus.wp ? "WP" : "PF"
		const fileName = `${periodAbbrName} ${financialYear} ${clientName} - ${fileType} Sign Off Summary`
		handleExport(data, fileName)
	}

	const areaWidth = store.ui.contentsAreaWidth - partialStore.viewListWidth
	const areaHeight = store.ui.contentsAreaHeight
	const columnsStatus = partialStore.viewColumns
	const dntFormat = store.global.getDntFormat

	console.log(
		partialStore.viewLastSignoffs(partialStore.selectedTab, dntFormat)
	)

	return (
		<StyledSignOffHistoryDetail
			areaWidth={areaWidth}
			areaHeight={areaHeight}
		>
			<div className="buttons-area FR AC JSB">
				<div className="FR AC JR">
					<DLIconButton
						eleTestId="table-expand-all-btn"
						tooltipText="Expand all"
						disabled={!needExpandAll}
						clickHandler={handleExpandAll}
					>
						<Icon path={mdiArrowExpandVertical} size={0.8} />
					</DLIconButton>
					<DLIconButton
						eleTestId="table-collapse-all-btn"
						tooltipText="Collapse all"
						disabled={!needCollapseAll}
						clickHandler={handleCollapseAll}
					>
						<Icon path={mdiUnfoldLessHorizontal} size={0.8} />
					</DLIconButton>
				</div>
				<div>
					{permission.export && (
						<div className="FR AC">
							<DLButton
								eleTestId="export-signoff-history-btn"
								startIcon={
									<Icon
										path={mdiFilePdf}
										size={0.8}
										color="red"
									/>
								}
								clickHandler={() =>
									partialStore.exportSignOffHistoryZip()
								}
							>
								Export Summary & Detail
							</DLButton>
							<div style={{ width: 8 }} />
							<DLButton
								eleTestId="export-summury-xlsx"
								startIcon={
									<Icon
										path={mdiFileExcel}
										size={0.8}
										color="green"
									/>
								}
								clickHandler={handleSignOffSummaryExport}
							>
								Export Summary
							</DLButton>
							<div style={{ width: 8 }} />
							<DLButton
								eleTestId="export-detail-xlsx"
								startIcon={
									<Icon
										path={mdiFileExcel}
										size={0.8}
										color="green"
									/>
								}
								clickHandler={() => {
									partialStore.setDetailHistoryDialogOpen(
										true
									)
								}}
							>
								Export Detail
							</DLButton>
						</div>
					)}
				</div>
			</div>
			<div className="contents-container">
				{partialStore.getActionStatus("getLastSignoffHistory") ===
				ActionStatus.success ? (
					<ReactTableV8
						tableColumns={SignoffhistoryTableColumns1(
							columnsStatus
						)}
						data={partialStore.viewLastSignoffs(
							partialStore.selectedTab,
							dntFormat
						)}
						menuId={DLProjSubMenus.signoff_history}
						showPageSetting={false}
						height={areaHeight - 40}
						groupBy={["parentId"]}
						renderSubcomponent={(row: any) => {
							const fileId = row.original.id
							return (
								<SignOffHistoryDetailMore
									fileId={fileId}
									fromTable={true}
								/>
							)
						}}
					/>
				) : (
					<div
						style={{ width: "100%", marginTop: "6rem" }}
						className="FR AC JC"
					>
						<DLSpinner />
					</div>
				)}
			</div>
			{partialStore.detailHistoryDialogOpen && (
				<ExportDetailHistoryDialog partialStore={partialStore} />
			)}
		</StyledSignOffHistoryDetail>
	)
})

const StyledSignOffHistoryDetail = styled.div<{
	areaWidth: number
	areaHeight: number
}>`
	margin-left: 2px;
	.buttons-area {
		height: ${(props) => props.theme.shared.baseUnit};
		padding-left: 2rem;
		padding-right: 1rem;
		.select-view-type {
		}
	}
	.contents-container {
		width: ${(props) => props.areaWidth}px;
		height: ${(props) => props.areaHeight}px;
		font-size: 12px;
		// background-color: ${(props) => props.theme.secondaryDeep};
		padding: 1rem;
	}
	table > thead > tr > th {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
`
