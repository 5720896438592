import React, { useState, useCallback, useEffect } from "react"
import {
	InputWithLabel,
	DLTextArea,
	DLRadioGroup,
	DLSingleSelect,
	DLChip,
} from "../../basic-elements"
import { DLAvatar } from "@datalobby/components"
import DLComboBoxMultipleValues from "../../basic-elements/autocomplete-fields/DLComboBoxMultipleValues"
import { DialogActionType } from "../../../common-models/enumerations/common-enums"
import { CommentStatus } from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import CommentTargetObjectInfo from "./CommentTargetObjectInfo"
import {
	DLObjectPropsForComment,
	DLCommentDetailFormInputProps,
} from "../../../common-models/types/comment-props"
import { DLCommentType } from "../../../common-models/enumerations/common-enums"

import styled from "styled-components"
import { UserForSelection } from "../../../common-models/types/common-props"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	DLProjMenuGroups,
	DLProjSubMenus,
} from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default function CommentDetailForm({
	mode,
	from,
	//
	objectInfo,
	assignableUserList,
	//
	commentInfo,
	inputs,
	handleInputs,
	handleAssignees,
	//
	projRole,
}: {
	mode: DialogActionType
	from: DLOrgSubMenus | DLProjMenuGroups | DLProjSubMenus
	//
	objectInfo: DLObjectPropsForComment
	assignableUserList: UserForSelection[] | undefined
	//
	commentInfo: any
	inputs: DLCommentDetailFormInputProps
	handleInputs: any
	handleAssignees: any
	//
	projRole: string
}) {
	const defaultValue = assignableUserList?.filter((item: any) =>
		inputs.assigneeIds?.includes(item.id)
	)
	const isCompleted = commentInfo?.status === CommentStatus.done

	const addMode = mode === DialogActionType.add
	const editMode = mode === DialogActionType.edit
	const readMode = mode === DialogActionType.read

	console.log(assignableUserList, inputs, defaultValue, "assignableUserList")
	return (
		<StyledCommentDetailForm className="FR">
			<div className="input-area">
				<div
					className="input-section FR"
					data-testid="comment-obj-info"
				>
					<InputWithLabel label="Comment On">
						<CommentTargetObjectInfo object={objectInfo} />
					</InputWithLabel>
				</div>
				{!addMode && (
					<div
						className="input-section FR"
						data-testid="comment-creation-info"
					>
						<InputWithLabel label="Comment Info">
							<div data-testid="createdBy">
								{/* NOTE: Do not destructure commentInfo because 'createdBy' does not exist in add Mode  */}
								Created by: {commentInfo.createdBy.name}
							</div>
							<div data-testid="createdAt">
								Created at: {commentInfo.createdAt}
							</div>

							{commentInfo.closedBy !== "-" && (
								<div
									className="closed-by"
									data-testid="closedBy"
								>
									Closed by: {commentInfo.closedBy}
								</div>
							)}
							{commentInfo.closedAt !== "-" && (
								<div data-testid="closedBy">
									Closed at: {commentInfo.closedAt}
								</div>
							)}
						</InputWithLabel>
					</div>
				)}

				<div
					className="input-section FR"
					data-testid="comment-priority"
				>
					<InputWithLabel label="Priority">
						<DLRadioGroup
							items={[
								{ value: "Low", label: "Low" },
								{ value: "Medium", label: "Medium" },
								{ value: "High", label: "High" },
							]}
							groupName="priority"
							selectedValue={inputs.priority}
							eleOnChange={handleInputs}
							itemDirection="row"
							disabled={isCompleted || readMode}
						/>
					</InputWithLabel>
				</div>
				{/* NOTE: cannot update comment type after creation */}
				{/* NOTE: except QC, other users cannot create QC comment  */}
				{/* {(!addMode || projRole === "QC") && ( */}
				<div className="input-section FR" data-testid="comment-type">
					<InputWithLabel label="Comment Type">
						<DLSingleSelect
							eleValue={inputs.type}
							eleOnChange={handleInputs}
							optionList={[
								{
									name: "Review Comment",
									value: DLCommentType.normal,
								},
								{
									name: "QC Comment",
									value: DLCommentType.qc,
								},
							]}
							eleTestId="type"
							eleName="type"
							// eleDisabled={
							// 	projRole !== "QC" ||
							// 	isCompleted ||
							// 	editMode ||
							// 	readMode
							// }
							eleDisabled
						/>
					</InputWithLabel>
				</div>
				{/* )} */}

				<div
					className="input-section FR"
					data-testid="comment-assignees"
				>
					<InputWithLabel label="Assignees" required>
						{assignableUserList !== undefined ? (
							<DLComboBoxMultipleValues
								eleDisabled={isCompleted || readMode}
								options={assignableUserList}
								eleTestId="comment-assignee-select"
								defaultValue={defaultValue}
								getOptionLabel={(user: any) => {
									let optionLabel =
										"(" +
										user.roleShortName +
										") " +
										user.aliasId +
										" " +
										user.name +
										" " +
										user.email

									if (user.status === "inactive") {
										optionLabel =
											"(deactivated) " + optionLabel
									}
									if (user.type === "external") {
										optionLabel =
											"(external) " + optionLabel
									}
									return optionLabel
								}}
								getOptionDisabled={(option: any) =>
									option.status === "inactive"
								}
								renderTags={(value: any[], getTagProps: any) =>
									value.map((option: any, index: number) => (
										<div key={index}>
											<DLChip
												eleTestId="selected-user-chip"
												icon={
													<DLAvatar
														size={18}
														fontSize={12}
														text={
															option.roleShortName
														}
														eleTestId={`${option.roleId}-avatar`}
														color={option.roleColor}
													/>
												}
												label={option.name}
											/>
										</div>
									))
								}
								onChange={handleAssignees}
								eleValue={defaultValue}
							/>
						) : (
							<p>Sorry, failed to get assignable user list.</p>
						)}
					</InputWithLabel>
				</div>

				<div
					className="input-section FR"
					data-testid="comment-text-field"
				>
					<InputWithLabel label="Comment" required>
						<DLTextArea
							eleTestId="input-comment-text"
							elePlaceholder="Please input comment"
							eleValue={inputs.contents}
							eleHandleChange={handleInputs}
							eleName="contents"
							eleClassName="comment-text-input-area"
							eleReadOnly={isCompleted || readMode}
						/>
					</InputWithLabel>
				</div>
			</div>
		</StyledCommentDetailForm>
	)
}

const StyledCommentDetailForm = styled.div`
	min-height: 400px;
	transition: 0.4s;
	padding-left: 0.5rem;
	.closed-by {
		margin-top: 0.4rem;
	}
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
		.comment-text-input-area {
			border: 1px solid ${(props) => props.theme.shade20};
			border-radius: 3px;
			transition: 0.4s;
			padding: 1rem;
			.MuiInput-underline:before {
				border-bottom: none;
				/* TODO: update is required */
			}
			.MuiInput-underline:after {
				border-bottom: none;
				/* TODO: update is required */
			}
		}
		/* .comment-text-input-area:focus {
			border: 1px solid red;
		} */
		.MuiInput-formControl {
			margin-top: 0px;
		}
	}
`
