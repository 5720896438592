import React from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, ConsoleLog } from "../../../../../components/basic-elements"
import styled from "styled-components"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import Icon from "@mdi/react"
import { mdiArrowRightBold } from "@mdi/js"
import BreadCrumbsAndOptions, {
	BreadCrumbsOptionProps,
} from "../../../../../components/combined-elements/postpone-to-classify/BreadCrumbsAndOptions"
import { DLObjectFormat } from "../../../../../common-models/enumerations/common-enums"
import { CreateProjectPathProps } from "../../../../../components/combined-elements/create-project/create-project.data-props"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectInfoContainer } from "../../../archive-management/common-dialogs/AmProjectDeleteDialog"

export default observer(function MoveGroupItemDialog({
	i18n,
	targetItemId,
	currentMenu,
}: {
	i18n: DLI18nProps
	targetItemId: string
	currentMenu: DLOrgSubMenus
}) {
	const orgStore = useOrgStore()
	//
	const open = orgStore.groups.openMoveDialog
	const handleOpen = orgStore.groups.setOpenMoveDialog

	const setSelectionPath = orgStore.groups.setPathForSelection

	const getLocationDetail = (id: string) => {
		if (id.includes("group")) {
			orgStore.groups.getOrgGroupCabinetList(id)
		} else if (id.includes("cabinet")) {
			orgStore.groups.getOrgCabinetDetail(id)
		} else {
			// TODO: Disable the item in the cabinet
		}
	}
	const handleMoveGroupItem = (
		target: any,
		destination: BreadCrumbsOptionProps
	) => {
		ConsoleLog(["target and destination", target, destination])
		if (target.type === DLObjectFormat.cabinet) {
			orgStore.groups.moveCabinet(target.id, destination.id)
		} else if (target.type === DLObjectFormat.project) {
			orgStore.groups.moveProject({
				projId: target.id,
				moveTo: destination.id,
				additionalAction: () => {
					if (currentMenu === DLOrgSubMenus.normal_projs) {
						orgStore.projects.customRefresh(
							DLOrgSubMenus.normal_projs,
							true
						)
						orgStore.groups.initializeStore()
					}
				},
			})
		}
	}

	const pathForSelection = orgStore.groups.selectionPathWithNameAndFormat
	const lastItem = pathForSelection[pathForSelection.length - 1]
	// const targetItemId =
	//     orgStore.groups.rightClickTarget || orgStore.projects.selectedProj
	const targetInfo = orgStore.groups.getTargetDetail(targetItemId)
	const getNameById = orgStore.groups.getNameById
	// const defaultOptions =
	let options: BreadCrumbsOptionProps[] = []
	if (lastItem) {
		options = orgStore.groups.getChildrenInTheFlatListAsOptions(lastItem.id)
		// if (
		// 	options.length === 0 &&
		// 	targetInfo?.type !== DLObjectFormat.project
		// ) {
		// orgStore.groups.getOrgCabinetDetail(lastItem.id)
		// }
	} else {
		options = orgStore.groups.groupOptions
	}

	let actionReady = true

	if (lastItem && lastItem.objFormat === DLObjectFormat.project) {
		actionReady = false
	}

	let idPath: string[] = []
	let cannotMoveReason = ""

	const lastPath = pathForSelection[pathForSelection.length - 1]
	pathForSelection.map((item) => idPath.push(item.id))

	if (targetInfo !== undefined) {
		if (
			idPath.includes(targetInfo.id) ||
			pathForSelection.length === 0 ||
			lastPath.objFormat === DLObjectFormat.project ||
			lastPath.objFormat === DLObjectFormat.group ||
			lastPath.id === targetInfo.id
		) {
			actionReady = false
		}
	}

	if (pathForSelection.length > 0) {
		if (targetInfo !== undefined) {
			if (lastPath.id === targetInfo.id) {
				cannotMoveReason = "Cannot move to oneself"
			} else if (
				lastPath.id !== targetInfo.id &&
				idPath.includes(targetInfo.id)
			) {
				cannotMoveReason = "Cannot move to children of the item"
			}
		}
	}
	if (lastPath && lastPath.objFormat === DLObjectFormat.project) {
		cannotMoveReason = "Cannot move to under the project"
	}
	if (lastPath && lastPath.objFormat === DLObjectFormat.group) {
		cannotMoveReason =
			"Cannot move to under the group directly. Please select a cabinet in the group"
	}

	const projectInfo =
		targetInfo?.type === DLObjectFormat.project
			? orgStore.groups.getSelectedProjInfo(targetInfo.id)
			: {}

	console.log(targetInfo, "targetInfo")

	return (
		<DLDialog
			eleTestId="select-destination-to-move-dialog"
			isOpen={open}
			setIsOpen={handleOpen}
			handleAction={() => handleMoveGroupItem(targetInfo, lastItem)}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={i18n.twMoveItem || "Move item"}
			dialogContents={
				<>
					{orgStore.groups.getActionStatus("getOrgCabinetDetail") !==
						"LOADING" && (
						<DialogContents
							path={pathForSelection}
							setPath={setSelectionPath}
							getLocationDetail={getLocationDetail}
							// defaultOptions={defaultOptions}
							options={options}
							targetItem={targetInfo}
							getNameById={getNameById}
							actionReady={actionReady}
							cannotMoveReason={cannotMoveReason}
							lastPath={lastPath}
							i18n={i18n}
							projectInfo={projectInfo}
						/>
					)}
				</>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={i18n.twMove || "Move"}
			actionReady={actionReady}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				orgStore.groups.getActionStatus("getOrgCabinetDetail") ===
				"LOADING"
			}
		/>
	)
})

const DialogContents = observer(
	({
		path,
		setPath,
		getLocationDetail,
		// defaultOptions,
		options,
		targetItem,
		getNameById,
		actionReady,
		cannotMoveReason,
		lastPath,
		i18n,
		projectInfo,
	}: {
		path: CreateProjectPathProps
		setPath: (path: any) => void
		getLocationDetail: (id: string) => void
		// defaultOptions: BreadCrumbsOptionProps[]
		options: BreadCrumbsOptionProps[]
		targetItem: any
		getNameById: (id: string) => string | undefined
		actionReady: boolean
		cannotMoveReason: string
		lastPath: any
		i18n: DLI18nProps
		projectInfo: any
	}) => {
		return (
			<>
				<BreadCrumbsAndOptions
					path={path}
					setPath={setPath}
					options={options}
					getLocationDetail={getLocationDetail}
					showLocationDetail
				/>
				<StyledTargetAndDestination>
					<div className="target-and-destination FR AC JSB">
						<div className="target-and-from">
							<div className="heading3">
								{i18n.twCurrentLocation || "Current Location"}
							</div>

							<div className="from">
								from: {getNameById(targetItem.parentId)}
							</div>
							<div className="target">{targetItem.title}</div>
						</div>
						<div className="arrow">
							<Icon path={mdiArrowRightBold} size={1} />
						</div>

						<div
							className={`destination ${
								!actionReady && "cannot-move"
							}`}
						>
							<div className="heading3">Next Location</div>
							{path.length > 0 ? (
								<span>
									{" "}
									To:{" "}
									{lastPath.id !== targetItem.parentId &&
										lastPath.name}
								</span>
							) : (
								<span>Please select destination</span>
							)}
						</div>
					</div>
					{!actionReady && (
						<div className="warning-message">
							! {cannotMoveReason}
						</div>
					)}

					{projectInfo?.title && (
						<ProjectInfoContainer
							projectDetails={projectInfo}
							i18n={i18n}
						/>
					)}
				</StyledTargetAndDestination>
			</>
		)
	}
)

const StyledTargetAndDestination = styled.div`
	.target-and-destination {
		/* .target-and-from {}
		.arrow {}
		.destination {}*/

		.target-and-from,
		.destination {
			height: 120px;
			width: 45%;
			.heading3 {
				font-weight: 600;
			}
		}
		.target-and-from {
			padding: 1rem;
			border-radius: 4px;
			background-color: ${(props) => props.theme.shade10};
			.target {
				margin-top: 0.6rem;
				padding: 0.6rem;
				border-radius: 3px;
				border: 1px solid gray;
			}
		}
		.arrow {
		}
		.destination {
			padding: 1rem;
			border-radius: 4px;
			background-color: ${(props) => props.theme.shade10};
			&.cannot-move {
				color: ${(props) => props.theme.warning};
				background-color: ${(props) => props.theme.background};
				border: 1px dashed;
			}
		}
	}
	.warning-message {
		color: ${(props) => props.theme.warning};
	}
`
