import { types, applySnapshot } from "mobx-state-tree"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
import {
	GetComments,
	GetCommentsByObj,
	AddComment,
	EditComment,
	RemoveComment,
	CloseComment,
} from "./view-model-actions"
import {
	CommentModel,
	CommentModelProps,
	CommentStatus,
	SimpleCommentModel,
} from "./data-models/comments.data-model"
import { initialStore } from "./comments.provider"
import {
	getTzDate,
	getTzDateAndTime2,
} from "../../../../../library/converters/date-utc-converter"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLCommentType } from "../../../../../common-models/enumerations/common-enums"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"

const CommentsViewModel = types
	.model({
		comments: types.array(CommentModel),
		//
		currentTargetObject: types.model({
			belongsTo: "",
			objectId: "",
		}), // for other modules.. ex) wp, pf
		commentsOfCurrentObject: types.array(SimpleCommentModel),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	//
	.actions((self) => ({
		resetComments() {
			self.comments.length = 0
		},
		//
		//
		//
		resetCurrentTargetObjectAndItsComment() {
			self.commentsOfCurrentObject.length = 0
			self.currentTargetObject = { belongsTo: "", objectId: "" }
		},
		resetCommentsOfCurrentObject() {
			self.commentsOfCurrentObject.length = 0
		},
		setComments(comments: any) {
			self.comments.length = 0
			self.comments = comments
		},
		setCurrentTargetObject(belongsTo: string, objectId: string) {
			self.currentTargetObject = { belongsTo, objectId }
		},
		setCommentsOfCurrentObject(comments: any) {
			self.commentsOfCurrentObject.length = 0
			self.commentsOfCurrentObject = comments
		},
		//
		//
		//
		pushItem(newItem: any) {
			const duplicatedItem = self.comments.findIndex(
				(comment) => comment.id === newItem.id
			)
			if (duplicatedItem === -1) {
				self.comments.push(newItem)
			}
		},
		reflectRemoveComment(commentId: string) {
			const targetIndex = self.comments.findIndex(
				(item) => item.id === commentId
			)
			if (targetIndex !== -1) {
				self.comments.splice(targetIndex, 1)
			} else {
				alert(`(reflectRemoveComment) Cannot find matched comment`)
			}
		},
		reflectUpdate(item: any) {
			const targetIndex = self.comments.findIndex(
				(comment) => comment.id === item.id
			)
			if (targetIndex === -1) {
			} else {
				self.comments.splice(targetIndex, 1)
				self.comments.push(item)
			}
		},

		updateCommentList(item: any) {
			const targetIndex = self.comments.findIndex(
				(comment) => comment.id === item.id
			)
			self.comments[targetIndex].contents = item.contents
			self.comments[targetIndex].priority = item.priority
			self.comments[targetIndex].assigneeIds = item.assigneeIds
		},

		reflectCommentClosing(inputData: any, fetchedData: any) {
			const targetIndex = self.comments.findIndex(
				(comment) => comment.id === inputData.id
			)
			console.log(self.comments[targetIndex], targetIndex, "fater")
			self.comments[targetIndex].status = CommentStatus.done
			self.comments[targetIndex].response = inputData.response
			self.comments[targetIndex].closedBy =
				fetchedData.Signoff + inputData.closedBy // @Noah
			self.comments[targetIndex].closedAt = fetchedData.SignOffDate
			console.log(self.comments[targetIndex], targetIndex, "fater1")
		},
	}))
	.views((self) => ({
		get commentsLengthOfCurrentObj() {
			return self.commentsOfCurrentObject.length
		},
		viewCommentsOfCurrentObject(dntFormat: DnTFormatProps) {
			let list: any[] = []
			self.commentsOfCurrentObject.map((comment) => {
				const formattedComment = {
					...comment,
					createdAt: getTzDateAndTime2({
						date: comment.createdAt,
						dntFormat,
					}),
				}
				list.push(formattedComment)
			})
			return list
		},
		getObjectInfo(commentId: string) {
			const targetComment = self.comments.find(
				(comment: CommentModelProps) => comment.id === commentId
			)
			if (targetComment) {
				const objectInfo = {
					id: targetComment.objectId,
					title: targetComment.objectName,
					aliasId: targetComment.objAliasId,
					srcFormat: targetComment.srcFormat,
					srcId: targetComment.srcFormat, // @Noah: Why this use src format for ID?
					//
					menuId: targetComment.menuId,
					status: targetComment.status,
				}
				return objectInfo
			} else {
				ConsoleLog("No matched comment")
			}
		},
		/**
		 * projects > comments
		 * @param dateFormat
		 * @param timeZone
		 * @param filterData
		 * @returns
		 */
		forExport(dateFormat: string, timeZone: string, filterData?: any) {
			let defaultList
			let formattedList: any[] = []
			if (filterData) {
				defaultList = filterData // TODO: Set filtering function
			} else {
				defaultList = self.comments
			}
			defaultList.map((comment: any) => {
				const formattedCreatedAt =
					comment.createdAt !== "" && comment.createdAt !== "-"
						? getTzDate({
								date: comment.createdAt,
								timeZone,
								dateFormat,
						  })
						: ""
				const formattedClosedAt =
					comment.closedAt !== "" && comment.closedAt !== "-"
						? getTzDate({
								date: comment.closedAt,
								timeZone,
								dateFormat,
						  })
						: ""
				const menuType =
					comment.menuId === DLProjSubMenus.wp
						? "Workpaper Files"
						: comment.menuId === DLProjSubMenus.pf
						? "Permanent Files"
						: "-"
				const type =
					comment.type === DLCommentType.normal
						? "Normal"
						: "QC Comment"
				const formattedComments = {
					Status: comment.status,
					Menu: menuType,
					Priority: comment.priority,
					"Ref.": comment.objAliasId,
					"File Name": comment.objectName,
					Type: type,
					Assignee: comment.assigneeNames.join(", "),
					Comment: comment.contents,
					"Created By": comment.createdBy,
					"Created At": formattedCreatedAt,
					Response: comment.response,
					"Closed By": comment.closedBy,
					"Closed At": formattedClosedAt,
				}
				formattedList.push(formattedComments)
			})

			return formattedList
		},
	}))
	// main actions (AGER)
	.actions(AddComment)
	.actions(GetComments)
	.actions(GetCommentsByObj)
	.actions(EditComment)
	.actions(RemoveComment)
	//
	.actions(CloseComment)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default CommentsViewModel
