import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import Rationales from "./Rationales"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import RationaleFinalReportDate from "./sub-components/RationaleFinalReportDate"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"
import { formattedDateWithTz } from "../../../../library/converters/date-utc-converter"
import { PageContainer } from "../../../../components/app-frame-elements"

export default observer(function RationalesController({
	fromDialog = false,
}: {
	fromDialog?: boolean
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.rationales
	const actionName = "getRationales"
	const i18nSheet = ProjI18n.rationale
	//
	const rationaleStore = projStore.rationales
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived

	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)
	const permissionForFinalReportDate = projStore.checkin.getActionsAsObject(
		DLProjSubMenus.proj_info,
		isLocked,
		isArchived
	)
	const needRefresh = projStore.rationales.needRefresh
	//
	useEffect(() => {
		const projId = projStore.checkin.projId
		if (menuAccess && needRefresh) {
			projStore.rationales.getRationales()
			projStore.projInfo.getProjInfo(projId)
		}
	}, [needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)
	let fetchingStatus = projStore.rationales.getActionStatus(actionName)

	const dntFormat = store.global.getDntFormat
	const dateFormat = dntFormat.dateFormat
	const timeZone = dntFormat.timeZone

	const timeFormat = dntFormat.timeFormat

	const tzUi = store.global.timeZone.uiValue
	const tzValue = store.global.timeZone.value

	const finalReportDate =
		rationaleStore.finalReportDate ||
		projStore.projInfo.projInfo.finalReportDate

	const formattedFinalReportDate = () =>
		formattedDateWithTz({
			date: finalReportDate,
			dateFormat,
			timeZone,
			timeFormat,
			tzLabel: tzValue,
		})

	return (
		<>
			{menuAccess ? (
				<PageContainer
					noPadding={fromDialog}
					isLoading={fetchingStatus === "LOADING"}
					hasToolbar={false}
				>
					{fetchingStatus === "SUCCESS" && (
						<>
							{/* NOTE: NEED TO SET THE DATA TYPE PROPERLY. file info and rationale has different data type for finalReportDate */}
							{finalReportDate ? (
								<Rationales
									partialStore={rationaleStore}
									permission={permission}
									formattedList={rationaleStore.formattedList(
										dateFormat,
										tzUi
									)}
									userList={projStore.projUsers.viewProjectUserIds()}
									isLocked={
										projStore.projInfo.projInfo.isLocked
									}
									finalReportDate={formattedFinalReportDate()}
									i18n={i18n}
									fromDialog={fromDialog}
								/>
							) : (
								<RationaleFinalReportDate
									permission={permissionForFinalReportDate}
								/>
							)}
						</>
					)}
				</PageContainer>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
